import React, { useEffect, useState } from "react";
import "./HomeProject.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {projectsData} from "../../LocalData/LocalData";
import useFunctions from "../../Hooks/useFunction";

const HomeProject = () => {
  const [activeButton, setActiveButton] = useState("");
  const [serviceFilter, setServiceFilter] = useState("");
  const [visibleCards, setVisibleCards] = useState(3);
  const [induvidualProjectId, setInduvidualProjectId] = useState(0);
  const {convertToBreakLines} = useFunctions();

  const handleLoadMore = () => {
    setVisibleCards((prev) => prev + 3);
  };


  const projectData = projectsData.filter(project => project.id === induvidualProjectId);


  // Optional: Update active button on mount
  useEffect(() => {
    if (activeButton === "") {
      setActiveButton("show-all");
    } else {
      setActiveButton("show-all");
    }
  }, []);

  // Handlers for button clicks

  const handleButtonAllClick = () => {
    setActiveButton("show-all");
    setServiceFilter("show-all");
  };

  const handleButtonWebClick = () => {
    setActiveButton("Web Development");
    setServiceFilter("Web Development");
  };

  const handleButtonMobileClick = () => {
    setActiveButton("Mobile App Development");
    setServiceFilter("Mobile App Development");
  };

  const handleButtonAPIClick = () => {
    setActiveButton("API Integration");
    setServiceFilter("API Integration");
  };

  const handleButtonQAClick = () => {
    setActiveButton("SQA Testing");
    setServiceFilter("SQA Testing");
  };

  const handleButtonAutomationClick = () => {
    setActiveButton("Script Bot Automation");
    setServiceFilter("Script Bot Automation");
  };

  const handleButtonUIClick = () => {
    setActiveButton("UI UX Design");
    setServiceFilter("UI UX Design");
  };

  const handleButtonGraphicsClick = () => {
    setActiveButton("Graphics Design");
    setServiceFilter("Graphics Design");
  };

  const handleButtonVideoClick = () => {
    setActiveButton("Motion Graphics Video Editing");
    setServiceFilter("Motion Graphics Video Editing");
  };

 

    // Silk Carousel
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

  return (
    <>
      <div>
        <div className="home-project-title-div">
          <h3>
            {" "}
            Discover Our Advanced <span>Project Solutions</span>
          </h3>
          <p>
            Enter our realm of creativity, where each project reveals a unique
            journey. This isn't merely a display—it's a glimpse into the future
            we're shaping, one innovation at a time.
          </p>
        </div>

        {/* <div className="row gx-5 gy-5 home-service-div">
        <div
              className="col-xlg-4 col-lg-4 col-md-6 col-sm-12 col-xsm-12"
           
            >
              <div className="home-services-card">
                <div className="home-services-card-inner">
                  <div className="home-services-card-box">
                    <div className="home-services-card-img-box">
                    <div className="home-services-card-img-box">
                    <img src={softwareDevelopment} alt="services_image" />
                  </div>
                    </div>
                    <div className="home-services-card-icon" >
                      <span
                       data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                      >
                        <a>
                          <i className="fa-solid fa-arrow-right"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="home-services-card-content">
                  <h3>Website & Software Development</h3>
                  <p>     We deliver custom website and software development solutions
                  to drive business growth and innovation.</p>
                </div>
              </div>
            </div>

          <div className="custom-button-div">
            <button
              className="custom-common-btn btn-3"
            
            >
              <span>View All Projects</span>
            </button>
          </div>
        </div> */}

<div className="project-filter-button-div">
          <button
            className={`project-filter-button-div-button ${
              activeButton === "show-all" ? "active" : ""
            }`}
            onClick={handleButtonAllClick}
          >
            All
          </button>
          <button
            className={`project-filter-button-div-button ${
              activeButton === "Web Development" ? "active" : ""
            }`}
            onClick={handleButtonWebClick}
          >
            Web Development
          </button>
          <button
            className={`project-filter-button-div-button ${
              activeButton === "Mobile App Development" ? "active" : ""
            }`}
            onClick={handleButtonMobileClick}
          >
            Mobile App Development
          </button>
          <button
            className={`project-filter-button-div-button ${
              activeButton === "API Integration" ? "active" : ""
            }`}
            onClick={handleButtonAPIClick}
          >
            API Integration
          </button>
          <button
            className={`project-filter-button-div-button ${
              activeButton === "SQA Testing" ? "active" : ""
            }`}
            onClick={handleButtonQAClick}
          >
            SQA & Testing
          </button>
          <button
            className={`project-filter-button-div-button ${
              activeButton === "Script Bot Automation" ? "active" : ""
            }`}
            onClick={handleButtonAutomationClick}
          >
            Script, Bot & Automation
          </button>
          <button
            className={`project-filter-button-div-button ${
              activeButton === "UI UX Design" ? "active" : ""
            }`}
            onClick={handleButtonUIClick}
          >
            UI/UX Design
          </button>
          <button
            className={`project-filter-button-div-button ${
              activeButton === "Graphics Design" ? "active" : ""
            }`}
            onClick={handleButtonGraphicsClick}
          >
            Graphics Design
          </button>
          <button
            className={`project-filter-button-div-button ${
              activeButton === "Motion Graphics Video Editing" ? "active" : ""
            }`}
            onClick={handleButtonVideoClick}
          >
            Motion Graphics & Video Editing
          </button>
        </div>

<div className="projects-card-div">
          <div className="row gx-5 gy-5 home-service-div projects-card-custom-div">
            {projectsData && projectsData.slice(0, visibleCards)
              .filter((item) => {
                if (serviceFilter === "Web Development") {
                  return item.category === serviceFilter;
                } else if (serviceFilter === "Mobile App Development") {
                  return item.category === serviceFilter;
                } else if (serviceFilter === "API Integration") {
                  return item.category === serviceFilter;
                } else if (serviceFilter === "SQA Testing") {
                  return item.category === serviceFilter;
                } else if (serviceFilter === "Script Bot Automation") {
                  return item.category === serviceFilter;
                } else if (serviceFilter === "UI UX Design") {
                  return item.category === serviceFilter;
                } else if (serviceFilter === "Graphics Design") {
                  return item.category === serviceFilter;
                } else if (
                  serviceFilter === "Motion Graphics Video Editing"
                ) {
                  return item.category === serviceFilter;
                } else return item;
              })
            .map((item) => (
              <div
                className="col-xlg-4 col-lg-4 col-md-6 col-sm-12 col-xsm-12"
                key={item.id}
              >
                <div className="home-services-card">
                  <div className="home-services-card-inner">
                    <div className="home-services-card-box">
                      <div className="home-services-card-img-box">
                      {
                        item.imageData.length > 0 && (
                          <iframe
                            src={`https://drive.google.com/file/d/${item.imageData[0].image_file_ID}/preview`}
                          
                          ></iframe> 
                        )
                      }
                      </div>
                      <div className="home-services-card-icon" onClick={() => setInduvidualProjectId(item.id)}>
                        <span
                        data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <a >
                            <i className="fa-solid fa-arrow-right"></i>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="home-services-card-content">
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            ))}

        
          </div>

          <div className="custom-button-div">
          {visibleCards < projectsData.length && (
          <button className="custom-common-btn btn-3" onClick={handleLoadMore}>
          <span>More Projects</span>
        </button>
      )}
          </div>
        </div>
      </div>


      
      {/* ===================== */}
      {/* Project Details Modal */}
      {/* ===================== */}

      <div
        className="modal fade modal-xl"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        {
          projectData.map(item => (
            <div className="modal-dialog" key={item.id}>
            <div className="modal-content p-4">
              <div className="modal-header">
                <h1
                  className="modal-title fs-5 project-details-information-title"
                  id="exampleModalLabel"
                >
                  {item.title}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="project-details-information-div">
                  <p className="project-details-information-technology">
                    {item.technology}
                  </p>
  
                  <p className="project-details-information-description">
                    {convertToBreakLines(item.description)}
                  </p>
                </div>
  
                {item.videoId === "" ? (
                  <></>
                ) : (
                  <div className="project-details-information-video">
                    <iframe
                      src={`https://drive.google.com/file/d/${item.videoId}/preview`}
                      width="100%"
                      height="440"
                      allow="autoplay"
                    ></iframe>
                  </div>
                )}
  
  <div className="project-details-information-images-div mt-3">
                  <Slider {...settings}>
                    {item.imageData &&
                      item.imageData.map((item, index) => (
                        <div
                          className="project-details-information-images"
                          key={index + 1}
                        >
                          {item.image_file_ID === "" ? (
                            <></>
                          ) : (
                            <iframe
                              src={`https://drive.google.com/file/d/${item.image_file_ID}/preview`}
                              width="100%"
                              height="440px"
                              allow="fullscreen"
                            ></iframe>
                          )}
                        </div>
                      ))}
                  </Slider>
                </div>
              </div>
              <div className="modal-footer mt-5">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
          ))
        }
      
      </div>
    </>
  );
};

export default HomeProject;