import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";


const useFunctions = () => {
    const [activeButton, setActiveButton] = useState("");
    const navigate = useNavigate();

    const handleCustomWebClick = () => {
        setActiveButton('handleCustomWeb');
    }
  
    const handleMultiVendorClick = () => {
        setActiveButton('handleMultiVendor');
    }
  
    const handleCMSClick = () => {
        setActiveButton('handleCMS');
    }
  
    const handleCRMClick = () => {
        setActiveButton('handleCRM');
    }
  
  
    const handleLMSClick = () => {
        setActiveButton('handleLMS');
    }
  
    const handleERPClick = () => {
        setActiveButton('handleERP');
    }
  
    const handlePOSClick = () => {
        setActiveButton('handlePOS');
    }
  
    const handleWebDesignClick = () => {
        setActiveButton('handleWebDesign');
    }
  
    const handleMaintenanceClick = () => {
        setActiveButton('handleMaintenance');
    }
  
    const handleUIUXClick = () => {
        setActiveButton('handleUIUX');
    }
  
    const handleSEOClick = () => {
        setActiveButton('handleSEO');
    }
  
    const handleDeployClick = () => {
        setActiveButton('handleDeploy');
    }


    const handleServicesScroll = () => {
        const targetElement = document.getElementById("services");
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      };
  

      const handleAboutUsScroll = () => {
        const targetElement = document.getElementById("about-us");
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      };

      const handleContactScroll = () => {
        const targetElement = document.getElementById("contact-us");
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      };


      const handleProjectsScroll = () => {
        const targetElement = document.getElementById("projects");
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      };


      const handleBlogScroll = () => {
        const targetElement = document.getElementById("blog");
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      };

      const handleHomePage = () =>{
        navigate(`/`);
        window.scrollTo(0, 0);
      }


        // ======================================
  // Details Line Break functionality
  // ======================================
  const convertToBreakLines = (text) => {
    if (!text) {
      return null;
    }
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };



  
    return {
      
        activeButton,
        handleHomePage,
        handleServicesScroll,
        handleAboutUsScroll,
        handleContactScroll,
        handleProjectsScroll,
        handleBlogScroll,
        setActiveButton,
        handleCustomWebClick,
        handleMultiVendorClick,
        handleCMSClick,
        handleCRMClick,
        handleLMSClick,
        handleERPClick,
        handlePOSClick,
        handleWebDesignClick,
        handleMaintenanceClick,
        handleUIUXClick,
        handleSEOClick,
        handleDeployClick,
        convertToBreakLines
    };
  };
  
  export default useFunctions;
  
  
