import React from "react";
import "./Contact.css";
import BangladeshOfficeMap from "./BangladeshOfficeMap";
import UsaOfficeMap from "./UsaOfficeMap";
// import useFunctions from "../../Hooks/useFunctions";

const Contact = () => {
//   const { handleAddContactChange, handleAddContact, handleHomePage } = useFunctions();
  return (
    <>
      {/* Page Hader */}
   

      <div className="contact-section">
        <div className="row gx-5 gy-5">
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
            <div className="contact-form-header">
              <h3>
                Get in touch <span>with us</span>
              </h3>
            </div>

            <div className="contact-form-input-div">
              <form >
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Your Full Name"
                    name="name"
                    // onChange={handleAddContactChange}
                  />
                  <label for="floatingInput">Your Full Name</label>
                </div>

                <div className="form-floating mb-4">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Email"
                    name="email"
                    // onChange={handleAddContactChange}
                  />
                  <label for="floatingInput">Your Email Address</label>
                </div>

                <div className="form-floating mb-5">
                  <textarea
                    className="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style={{ height: "250px" }}
                    name="message"
                    // onChange={handleAddContactChange}
                  ></textarea>
                  <label for="floatingTextarea2">Message here</label>
                </div>

                <div className="contact-form-submit-btn">
                  <button type="button"> Get Started Now</button>
                </div>
              </form>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <div className="contact-form-header">
              <h3>
                Contact <span>Details</span>
              </h3>
            </div>

            <div className="contact-form-address-div">
              <div className="contact-form-address-details">
                <h4>Bangladesh Address</h4>
                <p>
                  <i className="fa-solid fa-location-dot"></i> H-417, R- 7,
                  Baridhara DOHS, Dhaka-1206
                </p>
                <p>
                  <i className="fa-solid fa-envelope"></i> info@fleek.com.bd
                </p>
                <p>
                  <i className="fa-solid fa-phone"></i> +880 1977016090
                </p>
                <p>
                  {" "}
                  <i className="fa-solid fa-clock"></i> Saturday to Thursday:
                  9:00 AM - 6:00 PM
                </p>
              </div>

              <div className="contact-form-address-details">
                <h4>USA Address</h4>
                <p>
                  <i className="fa-solid fa-location-dot"></i>  4885 Route 9
Staatsburg, New York 12581-0369,
Hyde Park
USA
                </p>
                <p>
                  <i className="fa-solid fa-envelope"></i> info@fleek.com.bd
                </p>
                {/* <p>
                  <i className="fa-solid fa-phone"></i> +880 1977016090
                </p> */}
                {/* <p>
                  {" "}
                  <i className="fa-solid fa-clock"></i> Saturday to Thursday:
                  9:00 AM - 6:00 PM
                </p> */}
              </div>
            </div>
          </div>
        </div>

        <div className="row gy-5 contact-google-map-div">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
            {/* <BangladeshOfficeMap /> */}
            <div style={{ width: '100%', height: '400px' }}>
      <iframe
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d283.0911507940508!2d90.4130198!3d23.8121034!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c70036f76d97%3A0x8b917cf4ad1c566d!2sWISE%20Corporation!5e1!3m2!1sen!2sbd!4v1731384181289!5m2!1sen!2sbd" 
        width="100%" 
        height="100%" 
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>

    </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
            {/* <UsaOfficeMap /> */}
            <div style={{ width: '100%', height: '400px' }}>
      <iframe
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2763.7187170605903!2d-73.9369284991197!3d41.82882573219596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89dd13df5cdfc393%3A0x5638ef372727d4af!2s4885%20US-9%2C%20Staatsburg%2C%20NY%2012580%2C%20USA!5e1!3m2!1sen!2sbd!4v1731384317732!5m2!1sen!2sbd" 
        width="100%" 
        height="100%" 
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
          </div>
        </div>


      </div>
    </>
  );
};

export default Contact;