import React from "react";
import "./BusinessPartner.css";
import wise from "../../Images/business-partner/WiseBlue.png";
import eduWise from "../../Images/business-partner/eduwise.png";
import quickDigitalBangle from "../../Images/business-partner/quick-digital-bangla.png";
import greenExpectation from "../../Images/business-partner/greenExpectation.png";
import klever from "../../Images/business-partner/klever.png";
import vipal from "../../Images/business-partner/vipal.png";
import astech from "../../Images/business-partner/astech_logo.png";
import ksrm from "../../Images/business-partner/ksrm-logo.jpg";
import taher from "../../Images/business-partner/taher-group.png";
import regent from "../../Images/business-partner/logo-regent.png";
import bondhan from "../../Images/business-partner/bondhan.png";
import finWise from "../../Images/business-partner/finwise.png";
import tenderWise from "../../Images/business-partner/tenderWise.png";
import tutorWise from "../../Images/business-partner/tutorWise.png";
import sudokkho from "../../Images/business-partner/Sudokkho.png";
import insideBusiness from "../../Images/business-partner/inside-business.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BusinessPartner = () => {
  // Silk Carousel
  var settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="business-partener-img-card-div">
      <div className='home-blog-title-div' style={{marginBottom:"6rem"}}>
                    <h3> Showcase of  <span>Key Clients</span></h3>
                    <p>Explore a curated selection of impactful projects with our key clients, highlighting successful collaborations and standout solutions across various industries.</p>
                </div>

                
        <Slider {...settings}>
          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={wise}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={eduWise}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={finWise}
              alt="business_partener_logo"
            />
          </div>


          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={tenderWise}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={tutorWise}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={quickDigitalBangle}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={sudokkho}
              alt="business_partener_logo"
            />
          </div>


          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={insideBusiness}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={astech}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={ksrm}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={taher}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card" style={{background:"#000000"}}>
            <img
              className="business-partener-img"
              src={regent}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={bondhan}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={greenExpectation}
              alt="business_partener_logo"
            />
          </div>

       

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={klever}
              alt="business_partener_logo"
            />
          </div>

      

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={vipal}
              alt="business_partener_logo"
            />
          </div>


      
        </Slider>
      </div>
    </>
  );
};

export default BusinessPartner;