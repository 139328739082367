import React, { useState } from 'react';
import "./HomeBlog.css";
import {blogsData} from "../../LocalData/LocalData";
import useFunctions from '../../Hooks/useFunction';

const HomeBlog = () => {
    const [visibleCards, setVisibleCards] = useState(3);
    const [induvidualBlogId, setInduvidualBlogId] = useState(0);
    const {convertToBreakLines} = useFunctions();
  
    const handleLoadMore = () => {
      setVisibleCards((prev) => prev + 3);
    };

    const blogData = blogsData.filter(project => project.id === induvidualBlogId);

    return (
        <>
            <div className='home-blog-section'>
            <div className='home-blog-title-div'>
                    <h3> Current Blog <span>Highlights</span></h3>
                    <p>Uncover expert perspectives, practical tips, and compelling stories from a range of industries, featuring tech leaders, startups, and well-established companies.</p>
                </div>


                <div className='row gx-5 gy-5 home-blogs-div'>
                    {
                        blogsData && blogsData.slice(0, visibleCards)
                        .map(item => (
                            <div className="col-xlg-4 col-lg-4 col-md-6 col-sm-12 col-xsm-12" ket={item.id}> 
                            <div className="home-blogs-card">
                            <div className="home-blogs-card-inner">
                            <div className="home-blogs-card-box">
                            <div className="home-blogs-card-img-box">
                            {/* <img src={webDesign} alt="blogs_image"/> */}
                            <iframe
                                                        src={`https://drive.google.com/file/d/${item.blogImageId}/preview`}
                                                      
                                                      ></iframe> 
                            
                            
                            
                            </div>
                            <div className="home-blogs-card-icon" onClick={() => setInduvidualBlogId(item.id)}>
                            <span
                              data-bs-toggle="modal"
                          data-bs-target="#exampleModalBlog"
                            >
                            <a>
                             <i className="fa-solid fa-arrow-right"></i>
                            </a>
                            </span>
                            </div>
                            </div>
                            </div>
                            <div className="home-blogs-card-content">
                            <div className='home-blogs-card-content-top'>
                                <p>{item.blogType}</p>
                                <p>{item.readTime} min read</p>
                            </div>
                            
                            <div className='home-blogs-card-content-bottom'>
                                <p><i className="fa-regular fa-circle-user"></i> {item.publisherName} </p>
                            </div>
                            
                            <h3>{item.blogTitle}</h3>
                            <p>{item.blogDetails}</p>
                            
                            
                            </div>
                            </div>
                            
                            </div>
                                            ))
                    }
         

         <div className="custom-button-div">
          {visibleCards < blogsData.length && (
          <button className="custom-common-btn btn-3" onClick={handleLoadMore}>
          <span>More Blogs</span>
        </button>
      )}
          </div>
</div>
            </div>



      {/* ===================== */}
      {/* Blog Details Modal */}
      {/* ===================== */}

      <div
        className="modal fade modal-xl"
        id="exampleModalBlog"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        {
            blogData.map(blogDetailsData => (
                <div className="modal-dialog" key={blogDetailsData.id}>
                <div className="modal-content p-4">
                  <div className="modal-header">
                 
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                   
    
                  <div className="blog-details-discription-div">
                    <h3>{blogDetailsData.blogTitle}</h3>
    
                    <div className="row gx-5 gy-5 blog-details-description">
                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 '>
                            {/* <img src={lms} alt="blog_image"/> */}
                            <iframe
                                src={`https://drive.google.com/file/d/${blogDetailsData.blogImageId}/preview`}
    width="100%"
    height = "388px"
               ></iframe> 
                        </div>

                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 align-self-center'>
                            <p>{convertToBreakLines(blogDetailsData.blogDetails)}</p>
    
    <div className='d-flex justify-content-between align-items-center blog-post-date-div'>
        <p>Posted by {blogDetailsData.publisherName}</p>
        <p>{new Date(blogDetailsData.publishDate).toDateString()}</p>
    </div>
                        </div>
    
                        
                    </div>
    
                    {/* <div className='row gx-5 gy-5'>
    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
    <div className='recent-posts-div'>
    <h5>Recent Posts</h5>
    <div className='row gx-5 gy-5 recent-posts-details-div'>
        <div className='col-4 align-self-center'>
            <img src={lms} alt="post_images"/>
        </div>
    
        <div className='col-8 align-self-center'>
            <h4>How to choose the perfect LMS foe your organization: A comprehensive guide.</h4>
            <p>Business</p>
        </div>
    </div>
    
    <div className='row gx-5 gy-5 recent-posts-details-div'>
        <div className='col-4 align-self-center'>
            <img src={lms} alt="post_images"/>
        </div>
    
        <div className='col-8 align-self-center'>
            <h4>How to choose the perfect LMS foe your organization: A comprehensive guide.</h4>
            <p>Business</p>
        </div>
    </div>
    
    
    
    <div className='row gx-5 gy-5 recent-posts-details-div'>
        <div className='col-4 align-self-center'>
            <img src={lms} alt="post_images"/>
        </div>
    
        <div className='col-8 align-self-center'>
            <h4>How to choose the perfect LMS foe your organization: A comprehensive guide.</h4>
            <p>Business</p>
        </div>
    </div>
    <div>
    
    </div>
    </div>
    
    </div>
    
    
    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
    <div className='recent-posts-div'>
    <h5>Popular Posts</h5>
    <div className='row gx-5 gy-5 recent-posts-details-div'>
        <div className='col-4 align-self-center'>
            <img src={lms} alt="post_images"/>
        </div>
    
        <div className='col-8 align-self-center'>
            <h4>How to choose the perfect LMS foe your organization: A comprehensive guide.</h4>
            <p>Business</p>
        </div>
    </div>
    
    <div className='row gx-5 gy-5 recent-posts-details-div'>
        <div className='col-4 align-self-center'>
            <img src={lms} alt="post_images"/>
        </div>
    
        <div className='col-8 align-self-center'>
            <h4>How to choose the perfect LMS foe your organization: A comprehensive guide.</h4>
            <p>Business</p>
        </div>
    </div>
    
    
    
    <div className='row gx-5 gy-5 recent-posts-details-div'>
        <div className='col-4 align-self-center'>
            <img src={lms} alt="post_images"/>
        </div>
    
        <div className='col-8 align-self-center'>
            <h4>How to choose the perfect LMS foe your organization: A comprehensive guide.</h4>
            <p>Business</p>
        </div>
    </div>
    <div>
    
    </div>
    </div>
    </div>
                    </div> */}
                </div>
                  </div>
                  <div className="modal-footer mt-5">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            ))
        }
           
      
      </div>
        </>
    );
}

export default HomeBlog;