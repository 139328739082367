import React, { useState } from "react";
import "./Footer.css";
import whiteLogo from "../../Images/Fleek White .png";
import { NavLink } from "react-router-dom";
import useFunctions from "../../Hooks/useFunction";

import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem", visibility: "hidden" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)"
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)"
}));




const Footer = () => {
  const [expanded, setExpanded] = useState("panel1");

const {handleServicesScroll, handleAboutUsScroll, handleContactScroll,
  handleProjectsScroll,
  handleBlogScroll, handleHomePage} = useFunctions();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <>
      <div className="footer-section">
        <div className="footer-container">
          <div className="row gx-0 gy-5 ps-2 pe-2">
            <div className="col-xlg-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 ">
              <div className="footer-logo-div">
                <img src={whiteLogo} alt="fleek-logo" />
                <p className="me-5">
                  A smart and professional solution for all SaaS, software and
                  tech companies digital agencies.
                </p>
                <div className="footer-logo-social-div">
                  <span>
                    <a href="https://www.facebook.com/share/14GnLqTFMx/" target="_blank">
                      <i className="fa-brands fa-facebook"></i>
                    </a>
                  </span>

                  <span>
                    <a href="https://www.linkedin.com/company/fleekbangladesh/" target="_blank">
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                  </span>

                  <span>
                    <NavLink to="/">
                      <i className="fa-brands fa-square-x-twitter"></i>
                    </NavLink>
                  </span>

                  <span>
                    <NavLink to="/">
                      <i className="fa-brands fa-skype"></i>
                    </NavLink>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-xlg-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 ">
              <div className="footer-services-div">
                <h5 className="text-white">Services</h5>

                
                <p onClick={handleServicesScroll}>
                  Website & Software Development
                </p>

                <p onClick={handleServicesScroll}>Mobile App Development</p>

                <p onClick={handleServicesScroll}>
                  API Integration & Development
                </p>

                <p onClick={handleServicesScroll}>Software QA & Testing</p>

                <p onClick={handleServicesScroll}>Script, Bot & Automation</p>

                <p onClick={handleServicesScroll}>UI/UX Design & Development</p>

                <p onClick={handleServicesScroll}>Graphics Design</p>

                <p onClick={handleServicesScroll}>
                  Motion Graphics & Video Editing
                </p>
              </div>
            </div>

            <div className="col-xlg-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 ">
              <div className="footer-services-div">
                <h5 className="text-white">Company</h5>
                <p onClick={handleAboutUsScroll}>About Us</p>

                <p>Our Team</p>

                <p >Careers</p>

                <p onClick={handleContactScroll}>Contact</p>

                <p onClick={handleProjectsScroll}>Projects</p>

                <p onClick={handleBlogScroll}>Blog</p>

                <p  data-bs-toggle="modal"
                          data-bs-target="#exampleModalPrivacy">Privacy Policy</p>

                <p  data-bs-toggle="modal"
                          data-bs-target="#exampleModalFAQ">FAQ</p>
              </div>
            </div>

            <div className="col-xlg-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="footer-address-div">
                <h5 className="text-white">USA Office</h5>
                <p>
                  <span>
                    <i className="fa-solid fa-location-dot"></i>
                  </span>{" "}
                  4885 Route 9
Staatsburg, New York 12581-0369,
Hyde Park
USA
                </p>
                <p>
                  <span>
                    <i className="fa-solid fa-envelope"></i>
                  </span>{" "}
                  info@fleek.com.bd
                </p>
                {/* <p>
                  <span>
                    <i className="fa-solid fa-phone"></i>
                  </span>
                  +8801977016090
                </p> */}
              </div>

              <div className="footer-address-div">
                <h5 className="text-white mt-4">Bangladesh Office</h5>
                <p>
                  <span>
                    <i className="fa-solid fa-location-dot"></i>
                  </span>{" "}
                  H-417, R- 7, Baridhara DOHS, Dhaka-1206
                </p>
                <p>
                  <span>
                    <i className="fa-solid fa-envelope"></i>
                  </span>{" "}
                  info@fleek.com.bd
                </p>
                <p>
                  <span>
                    <i className="fa-solid fa-phone"></i>
                  </span>
                  +8801977016090
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom-section">
        <p>
          Copy{" "}
          <span>
            <i className="fa-regular fa-copyright"></i>
          </span>{" "}
          2024, All Rights Reserved by Fleek Bangladesh.
        </p>
      </div>



      {/* ===================== */}
      {/* Privacy Policy Modal */}
      {/* ===================== */}

      <div
        className="modal fade modal-xl"
        id="exampleModalPrivacy"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
        <div className="modal-content p-4">
            <div className="modal-header">
              {/* <h1
                className="modal-title fs-5 project-details-information-title"
                id="exampleModalLabel"
              >
               Modal Title API
              </h1> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
           

            <div className='privacy-policy-section'>
            <h3>Privacy and Policy page</h3>

            <div className='privacy-policy-details-div'>
                <p className='privacy-policy-details-top'>
                Protecting your privacy is a key commitment for Fleek Bangladesh. This Privacy Policy describes our practices regarding the collection, use, disclosure, and protection of your information on our website, <span style={{color:"#3b82f6", cursor:"pointer"}} onClick={handleHomePage} data-bs-dismiss="modal"
                aria-label="Close">www.fleek.com.bd</span> We urge you to review this Privacy Policy in detail. If you do not agree with its terms, please avoid using the Website.
                </p>

                <div className='privacy-policy-induvidual-div'>
                <h4>1. Information We Accumulate</h4>
                <p><i className="fa-solid fa-circle"></i> Personal Information: Includes your name, email address, phone number, and other comparable identifiers that you willingly provide through contact forms or when subscribing to our newsletter.</p>
                <p><i className="fa-solid fa-circle"></i> Technical Data: Technical details about your device and internet connection, including your IP address, browser type, operating system, and other related information.</p>
                <p><i className="fa-solid fa-circle"></i> Usage Data: Details on your interaction with our Website, including the pages you view, the duration spent on each page, referring site addresses, and other related statistics.</p>
                </div>


                <div className='privacy-policy-induvidual-div'>
                <h4>2. Cookies and Surveillance Technologies</h4>
                <p>Our Website may employ cookies and similar tracking tools (such as web beacons and pixels) to collect data and improve your user experience. Cookies are tiny files saved on your device that help us remember your preferences and settings, thus enhancing your browsing.</p>
                <p>You have the option to configure your browser to reject all cookies or to notify you whenever a cookie is being sent. However, if you choose not to accept cookies, some features of our Website may not be accessible.</p>
                </div>


                <div className='privacy-policy-induvidual-div'>
                <h4>3. Ways We Use Your Data</h4>
                <p><i className="fa-solid fa-circle"></i> To manage and run our Website.</p>
                <p><i className="fa-solid fa-circle"></i> To boost, personalize, and perfect your experience on our Website.</p>
                <p><i className="fa-solid fa-circle"></i> To evaluate trends and gather information on demographics.</p>
                <p><i className="fa-solid fa-circle"></i> To reach out to you, handle your queries, and dispatch newsletters, promotional messages, and updates about our products and services.</p>
                </div>


                <div className='privacy-policy-induvidual-div'>
                <h4>4. Revealing Your Data</h4>
                <p><i className="fa-solid fa-circle"></i> Service Providers: We may use third-party entities to manage our Website (Service Providers), deliver the Website's services, conduct related tasks, or help us analyze how our Website is utilized.</p>
                <p><i className="fa-solid fa-circle"></i> Business Transfers: During a merger, acquisition, or sale of Fleek Bangladesh.’s assets, your information might be transferred as part of the transaction.</p>
                <p><i className="fa-solid fa-circle"></i> Compliance with Laws: Your information may be transferred if Fleek Bangladesh is involved in a merger, acquisition, or the sale of some or all of its assets.</p>
                </div>

                <div className='privacy-policy-induvidual-div'>
                <h4>5. Information Security</h4>
                <p>Our security measures are designed to protect your information both online and offline. Nevertheless, because no method of transmitting data over the internet or storing it electronically is entirely secure, we cannot guarantee complete safety.</p>
                </div>

                <div className='privacy-policy-induvidual-div'>
                <h4>6. Your Entitlements and Choices</h4>
                <p>Your location may grant you various rights concerning your personal data, including the ability to access, amend, or remove it. You might also have the option to object to or restrict certain forms of data processing. To make use of these rights, please contact us through the information listed below.</p>
                </div>

                <div className='privacy-policy-induvidual-div'>
                <h4>7. Adjustments to This Privacy Policy</h4>
                <p>Our Privacy Policy may be updated periodically. We will inform you of any changes by posting the updated Privacy Policy on this page. You should check this Privacy Policy regularly to stay informed of any modifications.</p>
                </div>
            </div>
        </div>

            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>



        {/* ===================== */}
      {/* FAQ Modal */}
      {/* ===================== */}

      <div
        className="modal fade modal-xl"
        id="exampleModalFAQ"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
        <div className="modal-content p-4">
            <div className="modal-header">
              {/* <h1
                className="modal-title fs-5 project-details-information-title"
                id="exampleModalLabel"
              >
               Modal Title API
              </h1> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
           

            <div className="faq-section-title-div">
          <h2>
            Frequently Asked <span>Questions</span>
          </h2>
          <p>
            Interested in learning more about what we do at Fleek Bangladesh?
            You're not the only one! Below are some of the most common questions
            from our clients, providing you with a quick insight into how we
            drive innovation and success in each project
          </p>
        </div>


        <div className="row faq-body-container">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 faq-body-container-col">
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ margin: "0rem 0rem" }}
                className="faq-body-container-info-question-div"
                style={{ backgroundColor: "#ffffff" }}
              >
                <Typography className="web-category-healine-title faq-body-container-info-question">
                  <span>1.</span> What types of services does Fleek Bangladesh provide?
                </Typography>

                <IconButton
                  aria-label="expand"
                  size="large"
                  className="web-category-healine-icon-btn"
                >
                  {expanded === "panel1" ? (
                    <RemoveIcon sx={{ color: "#fd3f6d" }} />
                  ) : (
                    <AddIcon />
                  )}
                </IconButton>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq-body-container-info-answer">
                  Fleek Bangladesh offers a variety of services, such as custom
                  software development, mobile application development, web
                  development, UX/UI design, and IT consulting. We customize our
                  solutions to fit the specific requirements of every client.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ margin: "0rem 0rem" }}
                className="faq-body-container-info-question-div"
                style={{ backgroundColor: "#ffffff" }}
              >
                <Typography className=" faq-body-container-info-question">
                <span>2.</span> How does Fleek Bangladesh uphold the quality of its software
                  products?
                </Typography>

                <IconButton
                  aria-label="expand"
                  size="large"
                  className="web-category-healine-icon-btn"
                >
                  {expanded === "panel2" ? (
                    <RemoveIcon sx={{ color: "#fd3f6d" }} />
                  ) : (
                    <AddIcon />
                  )}
                </IconButton>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq-body-container-info-answer">
                  Ensuring quality is our top concern. We follow strict industry
                  protocols and engage in extensive testing and quality
                  assurance to confirm that our software solutions are reliable,
                  safe, and perform exceptionally.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ margin: "0rem 0rem" }}
                className="faq-body-container-info-question-div"
                style={{ backgroundColor: "#ffffff" }}
              >
                <Typography className=" faq-body-container-info-question">
                <span>3.</span> Which industries are the specialties of Fleek Bangladesh?
                </Typography>

                <IconButton
                  aria-label="expand"
                  size="large"
                  className="web-category-healine-icon-btn"
                >
                  {expanded === "panel3" ? (
                    <RemoveIcon sx={{ color: "#fd3f6d" }} />
                  ) : (
                    <AddIcon />
                  )}
                </IconButton>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq-body-container-info-answer">
                  Fleek Bangladesh brings experience from a variety of fields,
                  including Fintech, Healthcare, E-commerce, Real Estate,
                  Automotive, Education, Retail, and others. We grasp the
                  specific challenges and needs of each industry.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ margin: "0rem 0rem" }}
                className="faq-body-container-info-question-div"
                style={{ backgroundColor: "#ffffff" }}
              >
                <Typography className=" faq-body-container-info-question">
                <span>4.</span> What is the usual timeframe for completing a project?
                </Typography>

                <IconButton
                  aria-label="expand"
                  size="large"
                  className="web-category-healine-icon-btn"
                >
                  {expanded === "panel4" ? (
                    <RemoveIcon sx={{ color: "#fd3f6d" }} />
                  ) : (
                    <AddIcon />
                  )}
                </IconButton>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq-body-container-info-answer">
                  The timeline for every project differs based on its complexity
                  and scope. Following an initial consultation and project
                  evaluation, we deliver a detailed timeline and provide regular
                  updates during the development phase.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ margin: "0rem 0rem" }}
                className="faq-body-container-info-question-div"
                style={{ backgroundColor: "#ffffff" }}
              >
                <Typography className=" faq-body-container-info-question">
                <span>5.</span> Is Fleek Bangladesh capable of managing large-scale projects?
                </Typography>

                <IconButton
                  aria-label="expand"
                  size="large"
                  className="web-category-healine-icon-btn"
                >
                  {expanded === "panel5" ? (
                    <RemoveIcon sx={{ color: "#fd3f6d" }} />
                  ) : (
                    <AddIcon />
                  )}
                </IconButton>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq-body-container-info-answer">
                  Yes, Fleek Bangladesh possesses the expertise and resources
                  necessary for managing large-scale projects. Our team of
                  skilled professionals has experience with complex projects,
                  ensuring timely and budget-conscious completion.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ margin: "0rem 0rem" }}
                className="faq-body-container-info-question-div"
                style={{ backgroundColor: "#ffffff" }}
              >
                <Typography className=" faq-body-container-info-question">
                <span>6.</span> How do you update clients throughout the development process?
                </Typography>

                <IconButton
                  aria-label="expand"
                  size="large"
                  className="web-category-healine-icon-btn"
                >
                  {expanded === "panel6" ? (
                    <RemoveIcon sx={{ color: "#fd3f6d" }} />
                  ) : (
                    <AddIcon />
                  )}
                </IconButton>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq-body-container-info-answer">
                  Communication is key at Fleek Bangladesh. We provide regular
                  updates and progress reports throughout the development
                  process. Clients can also reach out to our project managers at
                  any time for updates or to discuss any concerns.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ margin: "0rem 0rem" }}
                className="faq-body-container-info-question-div"
                style={{ backgroundColor: "#ffffff" }}
              >
                <Typography className=" faq-body-container-info-question">
                <span>7.</span> Which technologies are utilized by Fleek Bangladesh?
                </Typography>

                <IconButton
                  aria-label="expand"
                  size="large"
                  className="web-category-healine-icon-btn"
                >
                  {expanded === "panel7" ? (
                    <RemoveIcon sx={{ color: "#fd3f6d" }} />
                  ) : (
                    <AddIcon />
                  )}
                </IconButton>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq-body-container-info-answer">
                  We employ a broad spectrum of technologies to ensure we meet
                  our clients' needs effectively. Our tech stack includes
                  React.js, Next.js, JavaScript, FastAPI, Webflow, MongoDB,
                  PostgreSQL, RapidAPI, BlackSheep, SQLAlchemy, Vector Database,
                  Docker, PHP, MySQL, Python, Django, Node.js, Express.js and
                  Laravel.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ margin: "0rem 0rem" }}
                className="faq-body-container-info-question-div"
                style={{ backgroundColor: "#ffffff" }}
              >
                <Typography className=" faq-body-container-info-question">
                <span>8.</span> Does Fleek Bangladesh offer support after launch?
                </Typography>

                <IconButton
                  aria-label="expand"
                  size="large"
                  className="web-category-healine-icon-btn"
                >
                  {expanded === "panel8" ? (
                    <RemoveIcon sx={{ color: "#fd3f6d" }} />
                  ) : (
                    <AddIcon />
                  )}
                </IconButton>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq-body-container-info-answer">
                  Definitely! We offer a full range of post-launch support and
                  maintenance to help your software operate effectively. Our
                  team is here to resolve any issues or carry out updates
                  whenever necessary.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ margin: "0rem 0rem" }}
                className="faq-body-container-info-question-div"
                style={{ backgroundColor: "#ffffff" }}
              >
                <Typography className=" faq-body-container-info-question">
                <span>9.</span> What’s the process for getting a quote for my project?
                </Typography>

                <IconButton
                  aria-label="expand"
                  size="large"
                  className="web-category-healine-icon-btn"
                >
                  {expanded === "panel9" ? (
                    <RemoveIcon sx={{ color: "#fd3f6d" }} />
                  ) : (
                    <AddIcon />
                  )}
                </IconButton>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq-body-container-info-answer">
                  To request a quote for your project, please contact us via our
                  website. We will set up a consultation to explore your
                  requirements and offer you a detailed proposal along with an
                  estimated cost.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel10"}
              onChange={handleChange("panel10")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ margin: "0rem 0rem" }}
                className="faq-body-container-info-question-div"
                style={{ backgroundColor: "#ffffff" }}
              >
                <Typography className=" faq-body-container-info-question">
                <span>10.</span> Why is Fleek Bangladesh a good option for my project?
                </Typography>

                <IconButton
                  aria-label="expand"
                  size="large"
                  className="web-category-healine-icon-btn"
                >
                  {expanded === "panel10" ? (
                    <RemoveIcon sx={{ color: "#fd3f6d" }} />
                  ) : (
                    <AddIcon />
                  )}
                </IconButton>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq-body-container-info-answer">
                  What sets Fleek Bangladesh apart is our exceptional skill,
                  commitment to excellence, and client-focused approach. We
                  engage with you to fully understand your needs and deliver
                  solutions that promote success. Our dedicated and creative
                  team ensures your project meets the highest standards.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
