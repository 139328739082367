import React, { useEffect, useRef, useState } from "react";
import "./HomeTechnology.css";
import { technologyImages } from "../../LocalData/LocalData";

const HomeTechnology = () => {
  const [activeButton, setActiveButton] = useState("");
  const [techImage, setTechImage] = useState("");
  const targetRef = useRef(null);

  // Optional: Update active button on mount
  useEffect(() => {
    if (activeButton === "") {
      setActiveButton("show-all");
      const filterTech = technologyImages.filter(
        (item) => item.category === "show-all"
      );
      setTechImage(filterTech);
    } else {
      setActiveButton("show-all");
    }
  }, []);

  // Handlers for button clicks
  const handleButtonAllClick = () => {
    setActiveButton("show-all");
    const filterTech = technologyImages.filter(
      (item) => item.category === "show-all"
    );
    setTechImage(filterTech);
    if (window.innerWidth <= 768) {
      targetRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleButtonWebClick = () => {
    setActiveButton("web-development");
    const filterTech = technologyImages.filter(
      (item) => item.category === "web-development"
    );
    setTechImage(filterTech);
    if (window.innerWidth <= 768) {
      targetRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleButtonMobileClick = () => {
    setActiveButton("mobile-development");
    const filterTech = technologyImages.filter(
      (item) => item.category === "mobile-development"
    );
    setTechImage(filterTech);
    if (window.innerWidth <= 768) {
      targetRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleButtonAPIClick = () => {
    setActiveButton("api-development");
    const filterTech = technologyImages.filter(
      (item) => item.category === "api-development"
    );
    setTechImage(filterTech);
    if (window.innerWidth <= 768) {
      targetRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleButtonQAClick = () => {
    setActiveButton("SQA-testing");
    const filterTech = technologyImages.filter(
      (item) => item.category === "SQA-testing"
    );
    setTechImage(filterTech);
    if (window.innerWidth <= 768) {
      targetRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleButtonAutomationClick = () => {
    setActiveButton("automation");
    const filterTech = technologyImages.filter(
      (item) => item.category === "automation"
    );
    setTechImage(filterTech);
    if (window.innerWidth <= 768) {
      targetRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleButtonDeploymentClick = () => {
    setActiveButton("deployment");
    const filterTech = technologyImages.filter(
      (item) => item.category === "deployment"
    );
    setTechImage(filterTech);
    if (window.innerWidth <= 768) {
      targetRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleButtonUIClick = () => {
    setActiveButton("ui-ux-design");
    const filterTech = technologyImages.filter(
      (item) => item.category === "ui-ux-design"
    );
    setTechImage(filterTech);
    if (window.innerWidth <= 768) {
      targetRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleButtonGraphicsClick = () => {
    setActiveButton("graphics-design");
    const filterTech = technologyImages.filter(
      (item) => item.category === "graphics-design"
    );
    setTechImage(filterTech);
    if (window.innerWidth <= 768) {
      targetRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleButtonMotionClick = () => {
    setActiveButton("motion-graphics");
    const filterTech = technologyImages.filter(
      (item) => item.category === "motion-graphics"
    );
    setTechImage(filterTech);
    if (window.innerWidth <= 768) {
      targetRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleButtonVideoClick = () => {
    setActiveButton("video-editing");
    const filterTech = technologyImages.filter(
      (item) => item.category === "video-editing"
    );
    setTechImage(filterTech);
    if (window.innerWidth <= 768) {
      targetRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div>
        <div className="home-technology-title-div">
          <h3>
            {" "}
            The Innovations <span>We Apply</span>
          </h3>
          <p>
            Explore the Technology We Deploy to Ensure Your Success. Find Out
            How Our Innovations Propel Your Business Forward.
          </p>
        </div>

        <div className="home-technology-body-div">
          <div className="row gx-2 gy-5 w-100">
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
              <div className="technology-select-button-div">
                <button
                  onClick={handleButtonAllClick}
                  className={`technology-select-button ${
                    activeButton === "show-all" ? "active" : ""
                  }`}
                >
                  Show All
                </button>

                <button
                  onClick={handleButtonWebClick}
                  className={`technology-select-button ${
                    activeButton === "web-development" ? "active" : ""
                  }`}
                >
                  Website Development
                </button>

                <button
                  onClick={handleButtonMobileClick}
                  className={`technology-select-button ${
                    activeButton === "mobile-development" ? "active" : ""
                  }`}
                >
                  Mobile App Development
                </button>

                <button
                  onClick={handleButtonAPIClick}
                  className={`technology-select-button ${
                    activeButton === "api-development" ? "active" : ""
                  }`}
                >
                  API Integration
                </button>

                <button
                  onClick={handleButtonQAClick}
                  className={`technology-select-button ${
                    activeButton === "SQA-testing" ? "active" : ""
                  }`}
                >
                  Software QA & Testing
                </button>

                <button
                  onClick={handleButtonAutomationClick}
                  className={`technology-select-button ${
                    activeButton === "automation" ? "active" : ""
                  }`}
                >
                  Script, Bot & Automation
                </button>

                <button
                  onClick={handleButtonDeploymentClick}
                  className={`technology-select-button ${
                    activeButton === "deployment" ? "active" : ""
                  }`}
                >
                  DevOps and Deployment
                </button>

                <button
                  onClick={handleButtonUIClick}
                  className={`technology-select-button ${
                    activeButton === "ui-ux-design" ? "active" : ""
                  }`}
                >
                  UI/UX Design & Dev.
                </button>

                <button
                  onClick={handleButtonGraphicsClick}
                  className={`technology-select-button ${
                    activeButton === "graphics-design" ? "active" : ""
                  }`}
                >
                  Graphics Design
                </button>

                <button
                  onClick={handleButtonMotionClick}
                  className={`technology-select-button ${
                    activeButton === "motion-graphics" ? "active" : ""
                  }`}
                >
                  Motion Graphics &  Video Editing
                </button>

                <button
                  onClick={handleButtonVideoClick}
                  className={`technology-select-button ${
                    activeButton === "video-editing" ? "active" : ""
                  }`}
                >
                Digital Marketing
                </button>
              </div>
            </div>

            <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12" id="targetElement" ref={targetRef}>
              {activeButton === "show-all" && (
                <>
                  <div className="row gx-1 gy-5">
                    {techImage.map((item) =>
                      item.technology.map((item) => (
                        <div
                          className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-xs-6 technology-select-button-icon-div-col"
                          key={item.id}
                        >
                          <div className="technology-select-button-icon-div">
                            <img src={item.icon} alt="technology-icon" />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </>
              )}

              {activeButton === "web-development" && (
                <>
                  <div className="row gx-2 gy-5">
                    {techImage.map((item) =>
                      item.technology.map((item) => (
                        <div
                          className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-xs-6 technology-select-button-icon-div-col"
                          key={item.id}
                        >
                          <div className="technology-select-button-icon-div">
                            <img src={item.icon} alt="technology-icon" />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </>
              )}

              {activeButton === "mobile-development" && (
                <>
                  <div className="row gx-2 gy-5">
                    {techImage.map((item) =>
                      item.technology.map((item) => (
                        <div
                          className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-xs-6 technology-select-button-icon-div-col"
                          key={item.id}
                        >
                          <div className="technology-select-button-icon-div">
                            <img src={item.icon} alt="technology-icon" />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </>
              )}

              {activeButton === "api-development" && (
                <>
                  <div className="row gx-2 gy-5">
                    {techImage.map((item) =>
                      item.technology.map((item) => (
                        <div
                          className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-xs-6 technology-select-button-icon-div-col"
                          key={item.id}
                        >
                          <div className="technology-select-button-icon-div">
                            <img src={item.icon} alt="technology-icon" />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </>
              )}

              {activeButton === "SQA-testing" && (
                <>
                  <div className="row gx-2 gy-5">
                    {techImage.map((item) =>
                      item.technology.map((item) => (
                        <div
                          className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-xs-6 technology-select-button-icon-div-col"
                          key={item.id}
                        >
                          <div className="technology-select-button-icon-div">
                            <img src={item.icon} alt="technology-icon" />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </>
              )}

              {activeButton === "automation" && (
                <>
                  <div className="row gx-2 gy-5">
                    {techImage.map((item) =>
                      item.technology.map((item) => (
                        <div
                          className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-xs-6 technology-select-button-icon-div-col"
                          key={item.id}
                        >
                          <div className="technology-select-button-icon-div">
                            <img src={item.icon} alt="technology-icon" />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </>
              )}

              {activeButton === "deployment" && (
                <>
                  <div className="row gx-2 gy-5">
                    {techImage.map((item) =>
                      item.technology.map((item) => (
                        <div
                          className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-xs-6 technology-select-button-icon-div-col"
                          key={item.id}
                        >
                          <div className="technology-select-button-icon-div">
                            <img src={item.icon} alt="technology-icon" />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </>
              )}

              {activeButton === "ui-ux-design" && (
                <>
                  <div className="row gx-2 gy-5">
                    {techImage.map((item) =>
                      item.technology.map((item) => (
                        <div
                          className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-xs-6 technology-select-button-icon-div-col"
                          key={item.id}
                        >
                          <div className="technology-select-button-icon-div">
                            <img src={item.icon} alt="technology-icon" />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </>
              )}

              {activeButton === "graphics-design" && (
                <>
                  <div className="row gx-2 gy-5">
                    {techImage.map((item) =>
                      item.technology.map((item) => (
                        <div
                          className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-xs-6 technology-select-button-icon-div-col"
                          key={item.id}
                        >
                          <div className="technology-select-button-icon-div">
                            <img src={item.icon} alt="technology-icon" />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </>
              )}

              {activeButton === "motion-graphics" && (
                <>
                  <div className="row gx-2 gy-5">
                    {techImage.map((item) =>
                      item.technology.map((item) => (
                        <div
                          className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-xs-6 technology-select-button-icon-div-col"
                          key={item.id}
                        >
                          <div className="technology-select-button-icon-div">
                            <img src={item.icon} alt="technology-icon" />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </>
              )}

              {activeButton === "video-editing" && (
                <>
                  <div className="row gx-2 gy-5">
                    {techImage.map((item) =>
                      item.technology.map((item) => (
                        <div
                          className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-xs-6 technology-select-button-icon-div-col"
                          key={item.id}
                        >
                          <div className="technology-select-button-icon-div">
                            <img src={item.icon} alt="technology-icon" />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeTechnology;