import React, { useEffect, useState } from "react";
import $ from "jquery";
import { NavLink, useNavigate } from "react-router-dom";
import "./Navbar.css";
import LightLogo from "../../Images/Fleek Nav.png";
import BlueLogo from "../../Images/Fleek Main Blue.png";
import { Link } from 'react-scroll';

const Navbar = () => {
  // JQuery for Navbar
  $(window).scroll(function () {
    if ($(document).scrollTop() < 1) {
      $(".header").removeClass("fixed");
      $(".header").removeClass("active");
    } else if ($(document).scrollTop() > 40) {
      $(".header").removeClass("fixed");
      $(".header").addClass("active");
    } else {
      $(".header").addClass("fixed");
    }
  });

  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  const handleScroll = () => {
    if (window.scrollY > 40) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll-menu-btn', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll-menu-btn', handleScroll);
    }

  }, []);


  const handleHomePage = () =>{
    navigate(`/`);
    window.scrollTo(0, 0);
  }



  return (
    <>
      <header className="header">
        <div className="top-header">
          <div className="top-header-section">
            <div>
              <a href="https://www.facebook.com/share/14GnLqTFMx/" target="_blank">
                <i className="fa-brands fa-facebook me-4"></i>
              </a>
              <a href="https://www.linkedin.com/company/fleekbangladesh/" target="_blank">
                <i className="fa-brands fa-linkedin me-4"></i>
              </a>
              <NavLink to="/">
                <i className="fa-brands fa-square-x-twitter me-4"></i>
              </NavLink>
              <NavLink to="/">
                <i className="fa-brands fa-skype"></i>
              </NavLink>
            </div>

            <div>
              <span className="ms-3 me-3">info@fleek.com.bd</span>
              <span className="me-3">+8801977016090</span>
            </div>
          </div>
        </div>
        <div className="container">
          <nav>
            <div className="logo">
              <a onClick={handleHomePage} style={{cursor:"pointer"}}>
              {
                isScrolled ? <>   <img src={BlueLogo} alt="logo" /></> :  <><img src={LightLogo} alt="logo" /></> 
              }
              
              </a>
            </div>

            <input type="checkbox" id="click" />
            <label for="click" className={isScrolled ? "menu-btn" : "scroll-menu-btn"}>
              <i className="fas fa-bars"></i>
            </label>
            <ul>
              {/* <li>
                <a onClick={handleHomePage} className={isScrolled ? 'scrolled' : 'nav-links'} activeClass="active" style={{cursor:"pointer"}}>
                  Home
                </a>
              </li> */}

              <li>
              
                  <Link to="about-us" smooth={true} duration={100}  style={{cursor:"pointer"}} className={isScrolled ? 'scrolled' : 'nav-links'}>
                    About Us
                    {/* <i className="fa-solid fa-angle-down down-arrow"></i> */}
                  </Link>
              
            
              </li>

              <li>
               
                  <Link to="services" smooth={true} duration={100}  style={{cursor:"pointer"}} className={isScrolled ? 'scrolled' : 'nav-links'}>
                    Services
                    {/* <i className="fa-solid fa-angle-down down-arrow"></i> */}
                  </Link>
           
              </li>

              <li>
                <Link to="projects" smooth={true} duration={100}  className={isScrolled ? 'scrolled' : 'nav-links'} style={{cursor:"pointer"}}>
                  Projects
                </Link>
              </li>

              <li>
                <Link to="blog" smooth={true} duration={100} className={isScrolled ? 'scrolled' : 'nav-links'} style={{cursor:"pointer"}}>
                  Blog
                </Link>
              </li>

              <li>
                <Link to="contact-us" smooth={true} duration={100} className={isScrolled ? 'scrolled' : 'nav-links'} style={{cursor:"pointer"}}>
                  Contact Us
                </Link>
              </li>

            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Navbar;