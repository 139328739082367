import React, { useEffect, useState } from "react";
import "./HomeServices.css";
import {servicesData} from "../../LocalData/LocalData"
import consultation from "../../Images/development-process-images/consultation.png";
import projectPlanning from "../../Images/development-process-images/project-planning.png";
import prototype from "../../Images/development-process-images/prototype.png";
import development from "../../Images/development-process-images/development.png";
import testing from "../../Images/development-process-images/testing.png";
import deployment from "../../Images/development-process-images/deployment.png";
import training from "../../Images/development-process-images/training.png";
import maintenance from "../../Images/development-process-images/maintenance.png";
import improvement from "../../Images/development-process-images/improvement.png";
import useFunctions from "../../Hooks/useFunction";



const HomeServices = () => {

  const [visibleCards, setVisibleCards] = useState(3);
  

  const handleLoadMore = () => {
    setVisibleCards((prev) => prev + 3);
  };

  const {  
    activeButton,
    setActiveButton,
    handleCustomWebClick,
    handleMultiVendorClick,
    handleCMSClick,
    handleCRMClick,
    handleLMSClick,
    handleERPClick,
    handlePOSClick,
    handleWebDesignClick,
    handleMaintenanceClick,
    handleUIUXClick,
    handleSEOClick,
    handleDeployClick} = useFunctions();

    // Optional: Update active button on mount
    useEffect(() => {
      if(activeButton === ""){
          setActiveButton('handleCustomWeb');
      } else{
          setActiveButton('handleCustomWeb');
      }
    }, []);

    

  return (
    <>
      <div className="home-services-section">
        <div className="home-title-div">
          <h3>
            Discover <span>Our Services</span>
          </h3>
          <p>Our Talented Team Transforms Your Ideas into Action</p>
        </div>

        <div className="row gx-5 gy-5 home-service-div">
        {servicesData.slice(0, visibleCards).map((item, index) => (
              <div className="col-xlg-4 col-lg-4 col-md-6 col-sm-12 col-xs-12" key={item.id}>
              <div className="home-services-card">
                <div className="home-services-card-inner">
                  <div className="home-services-card-box">
                    <div className="home-services-card-img-box">
                      <img src={item.image} alt="services_image" />
                    </div>
                    <div className="home-services-card-icon">
                      <span
                       data-bs-toggle="modal"
                          data-bs-target={item.modalId}
                      >
                        <a>
                          <i className="fa-solid fa-arrow-right"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="home-services-card-content">
                  <h3>{item.title} </h3>
                  <p>
                    {item.description}
                  </p>
                </div>
              </div>
            </div>
        ))}

          <div className="custom-button-div">
          {visibleCards < servicesData.length && (
          <button className="custom-common-btn btn-3" onClick={handleLoadMore}>
          <span>More Services</span>
        </button>
      )}

          
          </div>
        </div>
      </div>




        {/* ===================== */}
      {/* Website Services Modal */}
      {/* ===================== */}

      <div
        className="modal fade modal-xl"
        id="exampleModalweb"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
        <div className="modal-content p-4">
            <div className="modal-header">
              {/* <h1
                className="modal-title fs-5 project-details-information-title"
                id="exampleModalLabel"
                style={{fontSize:"50px"}}
              >
              Websit & Software Development
              </h1> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
            <div className='services-details-expertise-div'>
            <h3>Explore Our Service <span>Expertise</span></h3>
            <p className='services-details-expertise-div-p'>See How Our Expertise Transforms Challenges into Opportunities Through Tailored Service Solutions</p>

            <div className='services-details-expertise'>
                <div className='row gx-5 gy-5'>
                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                        <div className='services-details-expertise-title' onClick={handleCustomWebClick}>
                        <p> <i className="fa-solid fa-cloud"></i> Custom Web Application Development</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                        <div className='services-details-expertise-title' onClick={handleMultiVendorClick}>
                        <p> <i className="fa-solid fa-cloud"></i> Multi vendor eCommerce Development</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>


                        <div className='services-details-expertise-title' onClick={handleCMSClick}>
                        <p> <i className="fa-solid fa-cloud"></i> Content Management Systems (CMS)</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                        <div className='services-details-expertise-title' onClick={handleCRMClick}>
                        <p> <i className="fa-solid fa-cloud"></i> Customer Relationship Management (CRM)</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                        <div className='services-details-expertise-title' onClick={handleLMSClick}>
                        <p> <i className="fa-solid fa-cloud"></i> Learning Management System (LMS) </p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                        <div className='services-details-expertise-title' onClick={handleERPClick}>
                        <p> <i className="fa-solid fa-cloud"></i> Enterprise Resource Planning (ERP)</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                        <div className='services-details-expertise-title' onClick={handlePOSClick}>
                        <p> <i className="fa-solid fa-cloud"></i> Point of Sale (POS)</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                        <div className='services-details-expertise-title' onClick={handleWebDesignClick}>
                        <p> <i className="fa-solid fa-cloud"></i> Responsive Web Design</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                        <div className='services-details-expertise-title' onClick={handleMaintenanceClick}>
                        <p> <i className="fa-solid fa-cloud"></i> Website Maintenance and Support</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                        <div className='services-details-expertise-title' onClick={handleUIUXClick}>
                        <p> <i className="fa-solid fa-cloud"></i> UI/UX Design</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                        <div className='services-details-expertise-title' onClick={handleSEOClick}>
                        <p> <i className="fa-solid fa-cloud"></i> SEO and Digital Marketing</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                        <div className='services-details-expertise-title' onClick={handleDeployClick}>
                        <p> <i className="fa-solid fa-cloud"></i> Server Deploy and Domain Management</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                    </div>

                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>

                    {
    activeButton === 'handleCustomWeb' && 
    <div className='services-details-expertise-description'>
    <h3> Custom Web Application Development</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> User Authentication and Authorization</p>
    <p><i className="fa-solid fa-circle-dot"></i> Dashboard and User Interface</p>
    <p><i className="fa-solid fa-circle-dot"></i> Data Management </p>
    <p><i className="fa-solid fa-circle-dot"></i> Communication and Notifications</p>
    <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities</p>
    <p><i className="fa-solid fa-circle-dot"></i> Security Features</p>
    <p><i className="fa-solid fa-circle-dot"></i> Performance and Scalability</p>
    <p><i className="fa-solid fa-circle-dot"></i> Content Management</p>
    <p><i className="fa-solid fa-circle-dot"></i> Administrative Tools</p>
    <p><i className="fa-solid fa-circle-dot"></i> User Experience (UX) Enhancements</p>
    <p><i className="fa-solid fa-circle-dot"></i> Advanced Data Analytics</p>
    <p><i className="fa-solid fa-circle-dot"></i> Artificial Intelligence and Automation</p>
    <p><i className="fa-solid fa-circle-dot"></i> E-commerce Features</p>
    <p><i className="fa-solid fa-circle-dot"></i> Geo-Location and Mapping</p>
    <p><i className="fa-solid fa-circle-dot"></i> Multi-language and Localization</p>
    <p><i className="fa-solid fa-circle-dot"></i> Custom Workflows and Business Rules</p>
    <p><i className="fa-solid fa-circle-dot"></i> Third-Party Integrations</p>
                            </div>
    }

                    {
    activeButton === 'handleMultiVendor' && 
    <div className='services-details-expertise-description'>
    <h3>Multi vendor eCommerce Development</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> Vendor Management</p>
    <p><i className="fa-solid fa-circle-dot"></i> User Authentication and Authorization</p>
    <p><i className="fa-solid fa-circle-dot"></i> Product Features </p>
    <p><i className="fa-solid fa-circle-dot"></i> Order and Payment Management </p>
    <p><i className="fa-solid fa-circle-dot"></i> Customer Experience</p>
    <p><i className="fa-solid fa-circle-dot"></i> Admin Management</p>
    <p><i className="fa-solid fa-circle-dot"></i> Marketing and Promotions</p>
    <p><i className="fa-solid fa-circle-dot"></i> Shipping and Logistics</p>
    <p><i className="fa-solid fa-circle-dot"></i> Security and Compliance</p>
    <p><i className="fa-solid fa-circle-dot"></i> Support and Communication</p>
    <p><i className="fa-solid fa-circle-dot"></i> Customization and Scalability</p>
    <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities</p>
                            </div>
  }


  
{
    activeButton === 'handleCMS' && 
    <div className='services-details-expertise-description'>
    <h3>  Content Management Systems (CMS)</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> User Management</p>
    <p><i className="fa-solid fa-circle-dot"></i> Content Creation and Editing </p>
    <p><i className="fa-solid fa-circle-dot"></i> Content Organization </p>
    <p><i className="fa-solid fa-circle-dot"></i> Template and Design Management </p>
    <p><i className="fa-solid fa-circle-dot"></i> SEO and Metadata </p>
    <p><i className="fa-solid fa-circle-dot"></i> Search and Navigation</p>
    <p><i className="fa-solid fa-circle-dot"></i> Multimedia Management</p>
    <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting</p>
    <p><i className="fa-solid fa-circle-dot"></i> Security Features</p>
    <p><i className="fa-solid fa-circle-dot"></i> Multi-language and Localization</p>
    <p><i className="fa-solid fa-circle-dot"></i> API and Integrations</p>
                            </div>
    }


    
{
    activeButton === 'handleCRM' && 
    <div className='services-details-expertise-description'>
    <h3> Customer Relationship Management (CRM)</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> Contact Management</p>
    <p><i className="fa-solid fa-circle-dot"></i> Lead and Opportunity Management </p>
    <p><i className="fa-solid fa-circle-dot"></i> Sales Automation </p>
    <p><i className="fa-solid fa-circle-dot"></i> Customer Service and Support </p>
    <p><i className="fa-solid fa-circle-dot"></i> Email and Communication Tools </p>
    <p><i className="fa-solid fa-circle-dot"></i> Reporting and Analytics </p>
    <p><i className="fa-solid fa-circle-dot"></i> Task and Activity Management </p>
    <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
    <p><i className="fa-solid fa-circle-dot"></i> Data Management and Security </p>
    <p><i className="fa-solid fa-circle-dot"></i> Customization and Flexibility </p>
    <p><i className="fa-solid fa-circle-dot"></i> Contract and Agreement Management </p>
    <p><i className="fa-solid fa-circle-dot"></i> Integration with Financial Systems </p>
                            </div>
    }


    
{
    activeButton === 'handleLMS' && 
    <div className='services-details-expertise-description'>
    <h3> Learning Management System (LMS)</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> Course Management</p>
    <p><i className="fa-solid fa-circle-dot"></i> Content Delivery </p>
    <p><i className="fa-solid fa-circle-dot"></i> User Management </p>
    <p><i className="fa-solid fa-circle-dot"></i> Assessment and Testing </p>
    <p><i className="fa-solid fa-circle-dot"></i> Tracking and Reporting </p>
    <p><i className="fa-solid fa-circle-dot"></i> Communication and Collaboration </p>
    <p><i className="fa-solid fa-circle-dot"></i> Certification and Accreditation </p>
    <p><i className="fa-solid fa-circle-dot"></i> Mobile Access </p>
    <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
    <p><i className="fa-solid fa-circle-dot"></i> Customization and Branding </p>
                            </div>
    }


    
{
    activeButton === 'handleERP' && 
    <div className='services-details-expertise-description'>
    <h3> Enterprise Resource Planning (ERP)</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> Financial Management</p>
    <p><i className="fa-solid fa-circle-dot"></i> Human Resources Management (HRM) </p>
    <p><i className="fa-solid fa-circle-dot"></i> Supply Chain Management (SCM) </p>
    <p><i className="fa-solid fa-circle-dot"></i> Manufacturing and Production</p>
    <p><i className="fa-solid fa-circle-dot"></i> Customer Relationship Management (CRM) </p>
    <p><i className="fa-solid fa-circle-dot"></i> Project Management </p>
    <p><i className="fa-solid fa-circle-dot"></i> Sales and Distribution </p>
    <p><i className="fa-solid fa-circle-dot"></i> Business Intelligence and Reporting</p>
    <p><i className="fa-solid fa-circle-dot"></i> Procurement and Vendor Management</p>
    <p><i className="fa-solid fa-circle-dot"></i> Asset Managementy</p>
    <p><i className="fa-solid fa-circle-dot"></i> Customizable User Interface</p>
                            </div>
    }


    
{
    activeButton === 'handlePOS' && 
<div className='services-details-expertise-description'>
    <h3> Point of Sale (POS)</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> Sales and Transactions</p>
    <p><i className="fa-solid fa-circle-dot"></i> Inventory Management</p>
    <p><i className="fa-solid fa-circle-dot"></i> Customer Management </p>
    <p><i className="fa-solid fa-circle-dot"></i> Product Management </p>
    <p><i className="fa-solid fa-circle-dot"></i> Reporting and Analytics </p>
    <p><i className="fa-solid fa-circle-dot"></i> Employee Management </p>
    <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
    <p><i className="fa-solid fa-circle-dot"></i> User Interface and Usability </p>
    <p><i className="fa-solid fa-circle-dot"></i> Security Features </p>
    <p><i className="fa-solid fa-circle-dot"></i> Advanced Reporting and Analytics</p>
                            </div>
    }


    
{
    activeButton === 'handleWebDesign' && 
    <div className='services-details-expertise-description'>
    <h3> Responsive Web Design</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> Fluid Grid Layouts</p>
    <p><i className="fa-solid fa-circle-dot"></i> Flexible Images and Media </p>
    <p><i className="fa-solid fa-circle-dot"></i> Media Queries </p>
    <p><i className="fa-solid fa-circle-dot"></i> Responsive Typography </p>
    <p><i className="fa-solid fa-circle-dot"></i> Adaptive Navigation </p>
    <p><i className="fa-solid fa-circle-dot"></i> Viewport Meta Tag </p>
    <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
    <p><i className="fa-solid fa-circle-dot"></i> Cross-Browser Compatibility </p>
    <p><i className="fa-solid fa-circle-dot"></i> Accessibility Considerations </p>
    <p><i className="fa-solid fa-circle-dot"></i> Custom Breakpoints</p>
    <p><i className="fa-solid fa-circle-dot"></i> User Experience Testing</p>
                            </div>
    }


    
{
    activeButton === 'handleMaintenance' && 
    <div className='services-details-expertise-description'>
    <h3> Website Maintenance and Support</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> Regular Updates</p>
    <p><i className="fa-solid fa-circle-dot"></i> Backups and Recovery</p>
    <p><i className="fa-solid fa-circle-dot"></i> Security Monitoring </p>
    <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
    <p><i className="fa-solid fa-circle-dot"></i> Uptime Monitoring </p>
    <p><i className="fa-solid fa-circle-dot"></i> Bug Fixes and Troubleshooting </p>
    <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
    <p><i className="fa-solid fa-circle-dot"></i> Content Management </p>
    <p><i className="fa-solid fa-circle-dot"></i> User Experience Improvements </p>
    <p><i className="fa-solid fa-circle-dot"></i> Compliance Management </p>
    <p><i className="fa-solid fa-circle-dot"></i> Customer Support Integration</p>
                            </div>
    }


    
{
    activeButton === 'handleUIUX' && 
    <div className='services-details-expertise-description'>
    <h3>  UI/UX Design</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> Visual Design</p>
    <p><i className="fa-solid fa-circle-dot"></i> Layout and Structure </p>
    <p><i className="fa-solid fa-circle-dot"></i> Navigation </p>
    <p><i className="fa-solid fa-circle-dot"></i> Interactive Elements</p>
    <p><i className="fa-solid fa-circle-dot"></i> Feedback and Communication</p>
    <p><i className="fa-solid fa-circle-dot"></i> Accessibility </p>
    <p><i className="fa-solid fa-circle-dot"></i> User Research and Personas</p>
    <p><i className="fa-solid fa-circle-dot"></i> User Flow and Journey Mapping </p>
    <p><i className="fa-solid fa-circle-dot"></i> Support and Communication</p>
    <p><i className="fa-solid fa-circle-dot"></i> Performance and Efficiency</p>
    <p><i className="fa-solid fa-circle-dot"></i> Responsiveness and Adaptability</p>
                            </div>
    }

{
    activeButton === 'handleSEO' && 
<div className='services-details-expertise-description'>
    <h3> SEO and Digital Marketing</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> On-Page SEO</p>
    <p><i className="fa-solid fa-circle-dot"></i> Technical SEO </p>
    <p><i className="fa-solid fa-circle-dot"></i> Off-Page SEO </p>
    <p><i className="fa-solid fa-circle-dot"></i> Content SEO </p>
    <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
    <p><i className="fa-solid fa-circle-dot"></i> Local SEO Features </p>
    <p><i className="fa-solid fa-circle-dot"></i> Content Marketing </p>
    <p><i className="fa-solid fa-circle-dot"></i> Social Media Marketing </p>
    <p><i className="fa-solid fa-circle-dot"></i> Pay-Per-Click (PPC) Advertising </p>
    <p><i className="fa-solid fa-circle-dot"></i> Analytics and Insights</p>
    <p><i className="fa-solid fa-circle-dot"></i> Webinar and Event Marketing </p>
                            </div>
    }


{
    activeButton === 'handleDeploy' && 
    <div className='services-details-expertise-description'>
    <h3>  Server Deploy and Domain Management</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> Server Provisioning</p>
    <p><i className="fa-solid fa-circle-dot"></i> Server Configuration </p>
    <p><i className="fa-solid fa-circle-dot"></i> Automation and Orchestration </p>
    <p><i className="fa-solid fa-circle-dot"></i> Scalability and Load Balancing </p>
    <p><i className="fa-solid fa-circle-dot"></i> Security </p>
    <p><i className="fa-solid fa-circle-dot"></i> Monitoring and Performance</p>
    <p><i className="fa-solid fa-circle-dot"></i> Backup and Recovery</p>
    <p><i className="fa-solid fa-circle-dot"></i> Deployment Pipelines</p>
    <p><i className="fa-solid fa-circle-dot"></i> Access Control</p>
    <p><i className="fa-solid fa-circle-dot"></i>Traffic Analytics</p>
    <p><i className="fa-solid fa-circle-dot"></i> Compliance and Legal</p>
                            </div>
    }


                      
                    </div>

                </div>

            </div>

            </div>

            <div className='services-details-development-process-div'>
        <h3>Empower Your Business Potential Through Our Custom Web Development.</h3>
        <p className='services-details-development-process-div-p'>Creating and delivering smart solutions to help your business succeed in the future.</p>

        <div className='row gx-3 gy-3'>
        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
        <div className="services-process-card">
        <div className="services-process-card-body">
          <h4 className="services-process-card-title">Initial Consultation</h4>
          <div className="services-process-card-image" style={{color: "#213661"}}>
            <img src={consultation} alt="process_image"/>
          </div>
          <p className="services-process-card-text ">Our first step is to conduct a consultation to understand your business goals and vision, which helps us gather the necessary details to create a customized web development plan aligned with your objectives.</p>
        </div>
      </div>
        </div>


        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
        <div className="services-process-card">
        <div className="services-process-card-body">
        <h4 className="services-process-card-title">Requirement Analysis and Project Planning</h4>
          <div className="services-process-card-image" style={{color: "#213661"}}>
            <img src={projectPlanning} alt="process_image"/>
          </div>
          <p className="services-process-card-text ">We start with a comprehensive analysis of your requirements and develop a detailed project plan that specifies the scope, sets timelines, and allocates resources to ensure a well-structured and efficient development workflow.</p>
        </div>
      </div>
        </div>



        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
        <div className="services-process-card">
        <div className="services-process-card-body">
          <h4 className="services-process-card-title">Design Creation and Prototype Testing</h4>
          <div className="services-process-card-image" style={{color: "#213661"}}>
            <img src={prototype} alt="process_image"/>
          </div>
          <p className="services-process-card-text ">We produce wireframes and prototypes to showcase your website’s design and functionality. Our team prioritizes user experience (UX) and user interface (UI) to ensure the site is both easy to use and visually appealing. You will have the opportunity to review and offer feedback for design improvements.</p>
        </div>
      </div>
        </div>


        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
        <div className="services-process-card">
        <div className="services-process-card-body">
          <h4 className="services-process-card-title">Development</h4>
          <div className="services-process-card-image" style={{color: "#213661"}}>
            <img src={development} alt="process_image"/>
          </div>
          <p className="services-process-card-text ">Our expert developers transform the design into a functional website by crafting clean, efficient, and scalable code. Utilizing the latest technologies and best practices, we ensure your site is robust, secure, and performs optimally on all devices.</p>
        </div>
      </div>
        </div>


        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
        <div className="services-process-card">
        <div className="services-process-card-body">
          <h4 className="services-process-card-title">Testing and Quality Assurance</h4>
          <div className="services-process-card-image" style={{color: "#213661"}}>
            <img src={testing} alt="process_image"/>
          </div>
          <p className="services-process-card-text ">Quality is our highest concern. Our QA team rigorously tests the website to detect and address any issues. We undertake various testing methods—such as functional, performance, security, and user acceptance testing (UAT)—to ensure the website upholds the highest quality standards.</p>
        </div>
      </div>
        </div>


        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
        <div className="services-process-card">
        <div className="services-process-card-body">
          <h4 className="services-process-card-title">Deployment</h4>
          <div className="services-process-card-image" style={{color: "#213661"}}>
            <img src={deployment} alt="process_image"/>
          </div>
          <p className="services-process-card-text ">After the website clears all testing phases, we proceed with deployment. Our team facilitates a seamless transition to the live environment and offers support during the initial launch to address any immediate concerns and ensure smooth operation.</p>
        </div>
      </div>
        </div>


        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
        <div className="services-process-card">
        <div className="services-process-card-body">
          <h4 className="services-process-card-title">Training and Documentation</h4>
          <div className="services-process-card-image" style={{color: "#213661"}}>
            <img src={training} alt="process_image"/>
          </div>
          <p className="services-process-card-text ">We deliver extensive training to help you and your team operate the new website efficiently. Furthermore, we provide complete documentation that includes both user guides and technical information.</p>
        </div>
      </div>
        </div>

        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
        <div className="services-process-card">
        <div className="services-process-card-body">
          <h4 className="services-process-card-title">Maintenance and Support</h4>
          <div className="services-process-card-image" style={{color: "#213661"}}>
            <img src={maintenance} alt="process_image"/>
          </div>
          <p className="services-process-card-text ">After deployment, our support doesn’t end. We provide regular maintenance and support to keep your website updated and running efficiently. Our team is on standby 24/7 to address any issues, execute updates, and make sure the website aligns with your changing business requirements.</p>
        </div>
      </div>
        </div>

        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
        <div className="services-process-card">
        <div className="services-process-card-body">
          <h4 className="services-process-card-title">Continuous Improvement</h4>
          <div className="services-process-card-image" style={{color: "#213661"}}>
            <img src={improvement} alt="process_image"/>
          </div>
          <p className="services-process-card-text ">Our focus is on constant improvement. We partner with you to leverage user feedback and adapt to new business requirements, making ongoing enhancements to your website to ensure it remains effective and in sync with your long-term vision.</p>
        </div>
      </div>
        </div>

        </div>
        </div>

            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>


      {/* ===================== */}
      {/* Mobile App Services Modal */}
      {/* ===================== */}

      <div
        className="modal fade modal-xl"
        id="exampleModalmobile"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
        <div className="modal-content p-4">
            <div className="modal-header">
              {/* <h1
                className="modal-title fs-5 project-details-information-title"
                id="exampleModalLabel"
              >
               Modal Title Mobile App
              </h1> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
           

            <div className='services-details-expertise-div'>
            <h3>Explore Our Service <span>Expertise</span></h3>
            <p className='services-details-expertise-div-p'>See How Our Expertise Transforms Challenges into Opportunities Through Tailored Service Solutions</p>

            <div className='services-details-expertise'>
                <div className='row gx-5 gy-5'>
                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                        <div className='services-details-expertise-title' onClick={handleCustomWebClick}>
                        <p> <i className="fa-solid fa-cloud"></i> Custom Mobile App Development</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                        <div className='services-details-expertise-title' onClick={handleMultiVendorClick}>
                        <p> <i className="fa-solid fa-cloud"></i> Cross-Platform App Development</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>


                        <div className='services-details-expertise-title' onClick={handleCMSClick}>
                        <p> <i className="fa-solid fa-cloud"></i> Native App Development</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                        <div className='services-details-expertise-title' onClick={handleCRMClick}>
                        <p> <i className="fa-solid fa-cloud"></i> UI/UX Design</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                        <div className='services-details-expertise-title' onClick={handleLMSClick}>
                        <p> <i className="fa-solid fa-cloud"></i> Mobile App Testing </p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                        <div className='services-details-expertise-title' onClick={handleERPClick}>
                        <p> <i className="fa-solid fa-cloud"></i> Maintenance and Support</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                        <div className='services-details-expertise-title' onClick={handlePOSClick}>
                        <p> <i className="fa-solid fa-cloud"></i> App Store Optimization (ASO)</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                        <div className='services-details-expertise-title' onClick={handleWebDesignClick}>
                        <p> <i className="fa-solid fa-cloud"></i> Integration with Wearable Devices</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                        <div className='services-details-expertise-title' onClick={handleMaintenanceClick}>
                        <p> <i className="fa-solid fa-cloud"></i> Enterprise Mobility Solutions</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                        <div className='services-details-expertise-title' onClick={handleUIUXClick}>
                        <p> <i className="fa-solid fa-cloud"></i> Cloud-Based Mobile Apps</p>
                        <p><i className="fa-solid fa-arrow-right"></i></p>
                        </div>

                    </div>

                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>

                    {
    activeButton === 'handleCustomWeb' && 
    <div className='services-details-expertise-description'>
    <h3> Custom Mobile App Development</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> User Interface (UI) Design</p>
    <p><i className="fa-solid fa-circle-dot"></i> User Experience (UX) Design</p>
    <p><i className="fa-solid fa-circle-dot"></i> Functionality and Features </p>
    <p><i className="fa-solid fa-circle-dot"></i> Backend Development </p>
    <p><i className="fa-solid fa-circle-dot"></i> Security Features </p>
    <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
    <p><i className="fa-solid fa-circle-dot"></i> Cross-Platform Development </p>
    <p><i className="fa-solid fa-circle-dot"></i> App Store Integration </p>
    <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
    <p><i className="fa-solid fa-circle-dot"></i> Testing and Quality Assurance </p>
    <p><i className="fa-solid fa-circle-dot"></i> Geolocation Services </p>
    <p><i className="fa-solid fa-circle-dot"></i> Custom APIs </p>
    <p><i className="fa-solid fa-circle-dot"></i> User Feedback and Support</p>
                            </div>
    }

                    {
    activeButton === 'handleMultiVendor' && 
    <div className='services-details-expertise-description'>
    <h3>Cross-Platform App Development</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> Unified Codebase</p>
    <p><i className="fa-solid fa-circle-dot"></i> Frameworks and Tools</p>
    <p><i className="fa-solid fa-circle-dot"></i> User Interface (UI) Design </p>
    <p><i className="fa-solid fa-circle-dot"></i> Order and Payment Management </p>
    <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization</p>
    <p><i className="fa-solid fa-circle-dot"></i> Device and Platform Integration</p>
    <p><i className="fa-solid fa-circle-dot"></i> Cross-Platform Libraries</p>
    <p><i className="fa-solid fa-circle-dot"></i> Testing and Debugging</p>
    <p><i className="fa-solid fa-circle-dot"></i> Deployment and Distribution</p>
    <p><i className="fa-solid fa-circle-dot"></i> User Experience (UX)</p>
                            </div>
  }


  
{
    activeButton === 'handleCMS' && 
    <div className='services-details-expertise-description'>
    <h3>  Native App Development</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> Cross-Platform Compatibility</p>
    <p><i className="fa-solid fa-circle-dot"></i> User Interface (UI) and User Experience (UX)</p>
    <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
    <p><i className="fa-solid fa-circle-dot"></i> Device Hardware Integration </p>
    <p><i className="fa-solid fa-circle-dot"></i> Data Management and Offline Support </p>
    <p><i className="fa-solid fa-circle-dot"></i> Networking and API Integration</p>
    <p><i className="fa-solid fa-circle-dot"></i> Security Features</p>
    <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting</p>
    <p><i className="fa-solid fa-circle-dot"></i> App Testing and Debugging</p>
    <p><i className="fa-solid fa-circle-dot"></i> Deployment and Maintenance</p>
    <p><i className="fa-solid fa-circle-dot"></i> Development Workflow and CI/CD</p>
                            </div>
    }


    
{
    activeButton === 'handleCRM' && 
    <div className='services-details-expertise-description'>
    <h3> UI/UX Design</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> User-Centered Design</p>
    <p><i className="fa-solid fa-circle-dot"></i> Responsive Design </p>
    <p><i className="fa-solid fa-circle-dot"></i> Onboarding Experience </p>
    <p><i className="fa-solid fa-circle-dot"></i> Intuitive Navigation </p>
    <p><i className="fa-solid fa-circle-dot"></i> Accessibility </p>
    <p><i className="fa-solid fa-circle-dot"></i> Interactive Feedback </p>
    <p><i className="fa-solid fa-circle-dot"></i> Personalization </p>
    <p><i className="fa-solid fa-circle-dot"></i> Aesthetic Design </p>
    <p><i className="fa-solid fa-circle-dot"></i> Micro-Interactions </p>
    <p><i className="fa-solid fa-circle-dot"></i> Data Visualization </p>
    <p><i className="fa-solid fa-circle-dot"></i> Gesture-Based Navigation </p>
    <p><i className="fa-solid fa-circle-dot"></i> Seamless User Flow </p>
                            </div>
    }


    
{
    activeButton === 'handleLMS' && 
    <div className='services-details-expertise-description'>
    <h3> Mobile App Testing</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> Functional Testing</p>
    <p><i className="fa-solid fa-circle-dot"></i> Performance Testing </p>
    <p><i className="fa-solid fa-circle-dot"></i> Usability Testing </p>
    <p><i className="fa-solid fa-circle-dot"></i> Compatibility Testing </p>
    <p><i className="fa-solid fa-circle-dot"></i> Security Testing </p>
    <p><i className="fa-solid fa-circle-dot"></i> Network Testing </p>
    <p><i className="fa-solid fa-circle-dot"></i> Localization Testing </p>
    <p><i className="fa-solid fa-circle-dot"></i> Interrupt Testing </p>
    <p><i className="fa-solid fa-circle-dot"></i> Accessibility Testing </p>
    <p><i className="fa-solid fa-circle-dot"></i> Regression Testing </p>
    <p><i className="fa-solid fa-circle-dot"></i> Crash and Recovery Testing </p>
    <p><i className="fa-solid fa-circle-dot"></i> App Store Guidelines Testing </p>
    <p><i className="fa-solid fa-circle-dot"></i> Analytics and Monitoring Testing </p>
                            </div>
    }


    
{
    activeButton === 'handleERP' && 
    <div className='services-details-expertise-description'>
    <h3> Maintenance and Support</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> Bug Fixes and Issue Resolution</p>
    <p><i className="fa-solid fa-circle-dot"></i> Performance Monitoring and Optimization </p>
    <p><i className="fa-solid fa-circle-dot"></i> Regular Updates and Upgrades </p>
    <p><i className="fa-solid fa-circle-dot"></i> User Support</p>
    <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
    <p><i className="fa-solid fa-circle-dot"></i>  Security and Compliance Management </p>
    <p><i className="fa-solid fa-circle-dot"></i> Backup and Data Recovery </p>
    <p><i className="fa-solid fa-circle-dot"></i> Compatibility Maintenance </p>
    <p><i className="fa-solid fa-circle-dot"></i> Scaling and Load Management </p>
    <p><i className="fa-solid fa-circle-dot"></i> App Store Management </p>
    <p><i className="fa-solid fa-circle-dot"></i> Regular Audits </p>
    <p><i className="fa-solid fa-circle-dot"></i> User Engagement and Retention </p>
    <p><i className="fa-solid fa-circle-dot"></i> Documentation and Knowledge Transfer</p>
                            </div>
    }


    
{
    activeButton === 'handlePOS' && 
<div className='services-details-expertise-description'>
    <h3> App Store Optimization (ASO)</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> Keyword Optimization</p>
    <p><i className="fa-solid fa-circle-dot"></i> App Title and Icon </p>
    <p><i className="fa-solid fa-circle-dot"></i> App Description</p>
    <p><i className="fa-solid fa-circle-dot"></i> Compelling App Title and Description</p>
    <p><i className="fa-solid fa-circle-dot"></i> Visual Optimization </p>
    <p><i className="fa-solid fa-circle-dot"></i> User Ratings and Reviews </p>
    <p><i className="fa-solid fa-circle-dot"></i> Localization </p>
    <p><i className="fa-solid fa-circle-dot"></i>  App Updates </p>
    <p><i className="fa-solid fa-circle-dot"></i>  Download and Conversion Optimization </p>
    <p><i className="fa-solid fa-circle-dot"></i>  Competitor Analysis</p>
    <p><i className="fa-solid fa-circle-dot"></i>  Analytics and Tracking </p>
    <p><i className="fa-solid fa-circle-dot"></i>  Promotional Features </p>
    <p><i className="fa-solid fa-circle-dot"></i>  Compliance with Store Guidelines </p>
                            </div>
    }


    
{
    activeButton === 'handleWebDesign' && 
    <div className='services-details-expertise-description'>
    <h3> Integration with Wearable Devices</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> Connectivity and Compatibility</p>
    <p><i className="fa-solid fa-circle-dot"></i> Real-Time Data Synchronization</p>
    <p><i className="fa-solid fa-circle-dot"></i> Notifications and Alerts </p>
    <p><i className="fa-solid fa-circle-dot"></i> Device Control </p>
    <p><i className="fa-solid fa-circle-dot"></i> Health and Fitness Features </p>
    <p><i className="fa-solid fa-circle-dot"></i>  Location-Based Features </p>
    <p><i className="fa-solid fa-circle-dot"></i> Voice and Audio Features </p>
    <p><i className="fa-solid fa-circle-dot"></i> Custom User Interface </p>
    <p><i className="fa-solid fa-circle-dot"></i> Security and Privacy </p>
    <p><i className="fa-solid fa-circle-dot"></i> Advanced Features</p>
    <p><i className="fa-solid fa-circle-dot"></i> App and Wearable Integration</p>
                            </div>
    }


    
{
    activeButton === 'handleMaintenance' && 
    <div className='services-details-expertise-description'>
    <h3>Enterprise Mobility Solutions</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> Regular Updates</p>
    <p><i className="fa-solid fa-circle-dot"></i> Backups and Recovery</p>
    <p><i className="fa-solid fa-circle-dot"></i> Security Monitoring </p>
    <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
    <p><i className="fa-solid fa-circle-dot"></i> Uptime Monitoring </p>
    <p><i className="fa-solid fa-circle-dot"></i> Bug Fixes and Troubleshooting </p>
    <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
    <p><i className="fa-solid fa-circle-dot"></i> Content Management </p>
    <p><i className="fa-solid fa-circle-dot"></i> User Experience Improvements </p>
    <p><i className="fa-solid fa-circle-dot"></i> Compliance Management </p>
    <p><i className="fa-solid fa-circle-dot"></i> Customer Support Integration</p>
                            </div>
    }


    
{
    activeButton === 'handleUIUX' && 
    <div className='services-details-expertise-description'>
    <h3>  Cloud-Based Mobile Apps</h3>
    
    <p><i className="fa-solid fa-circle-dot"></i> Visual Design</p>
    <p><i className="fa-solid fa-circle-dot"></i> Layout and Structure </p>
    <p><i className="fa-solid fa-circle-dot"></i> Navigation </p>
    <p><i className="fa-solid fa-circle-dot"></i> Interactive Elements</p>
    <p><i className="fa-solid fa-circle-dot"></i> Feedback and Communication</p>
    <p><i className="fa-solid fa-circle-dot"></i> Accessibility </p>
    <p><i className="fa-solid fa-circle-dot"></i> User Research and Personas</p>
    <p><i className="fa-solid fa-circle-dot"></i> User Flow and Journey Mapping </p>
    <p><i className="fa-solid fa-circle-dot"></i> Support and Communication</p>
    <p><i className="fa-solid fa-circle-dot"></i> Performance and Efficiency</p>
    <p><i className="fa-solid fa-circle-dot"></i> Responsiveness and Adaptability</p>
                            </div>
    }
                      
                    </div>

                </div>

            </div>

            </div>
        
        <div className='services-details-development-process-div'>
        <h3>Empower Your Business Potential Through Our Mobile App Development.</h3>
        <p className='services-details-development-process-div-p'>Creating and delivering smart solutions to help your business succeed in the future.</p>

        <div className='row gx-3 gy-3'>
        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
        <div className="services-process-card">
        <div className="services-process-card-body">
          <h4 className="services-process-card-title">Initial Consultation</h4>
          <div className="services-process-card-image" style={{color: "#213661"}}>
            {/* <i className="fas fa-laptop-code fa-5x"></i> */}
            <img src={consultation} alt="process_image"/>
          </div>
          <p className="services-process-card-text ">Our first step is to conduct a consultation to understand your business goals and vision, which helps us gather the necessary details to create a customized web development plan aligned with your objectives.</p>
        </div>
      </div>
        </div>


        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
        <div className="services-process-card">
        <div className="services-process-card-body">
        <h4 className="services-process-card-title">Requirement Analysis and Project Planning</h4>
          <div className="services-process-card-image" style={{color: "#213661"}}>
            {/* <i className="fas fa-laptop-code fa-5x"></i> */}
            <img src={projectPlanning} alt="process_image"/>
          </div>
          <p className="services-process-card-text ">We start with a comprehensive analysis of your requirements and develop a detailed project plan that specifies the scope, sets timelines, and allocates resources to ensure a well-structured and efficient development workflow.</p>
        </div>
      </div>
        </div>



        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
        <div className="services-process-card">
        <div className="services-process-card-body">
          <h4 className="services-process-card-title">Design Creation and Prototype Testing</h4>
          <div className="services-process-card-image" style={{color: "#213661"}}>
            {/* <i className="fas fa-laptop-code fa-5x"></i> */}
            <img src={prototype} alt="process_image"/>
          </div>
          <p className="services-process-card-text ">We produce wireframes and prototypes to showcase your website’s design and functionality. Our team prioritizes user experience (UX) and user interface (UI) to ensure the site is both easy to use and visually appealing. You will have the opportunity to review and offer feedback for design improvements.</p>
        </div>
      </div>
        </div>


        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
        <div className="services-process-card">
        <div className="services-process-card-body">
          <h4 className="services-process-card-title">Development</h4>
          <div className="services-process-card-image" style={{color: "#213661"}}>
            {/* <i className="fas fa-laptop-code fa-5x"></i> */}
            <img src={development} alt="process_image"/>
          </div>
          <p className="services-process-card-text ">Our expert developers transform the design into a functional website by crafting clean, efficient, and scalable code. Utilizing the latest technologies and best practices, we ensure your site is robust, secure, and performs optimally on all devices.</p>
        </div>
      </div>
        </div>


        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
        <div className="services-process-card">
        <div className="services-process-card-body">
          <h4 className="services-process-card-title">Testing and Quality Assurance</h4>
          <div className="services-process-card-image" style={{color: "#213661"}}>
            {/* <i className="fas fa-laptop-code fa-5x"></i> */}
            <img src={testing} alt="process_image"/>
          </div>
          <p className="services-process-card-text ">Quality is our highest concern. Our QA team rigorously tests the website to detect and address any issues. We undertake various testing methods—such as functional, performance, security, and user acceptance testing (UAT)—to ensure the website upholds the highest quality standards.</p>
        </div>
      </div>
        </div>


        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
        <div className="services-process-card">
        <div className="services-process-card-body">
          <h4 className="services-process-card-title">Deployment</h4>
          <div className="services-process-card-image" style={{color: "#213661"}}>
            {/* <i className="fas fa-laptop-code fa-5x"></i> */}
            <img src={deployment} alt="process_image"/>
          </div>
          <p className="services-process-card-text ">After the website clears all testing phases, we proceed with deployment. Our team facilitates a seamless transition to the live environment and offers support during the initial launch to address any immediate concerns and ensure smooth operation.</p>
        </div>
      </div>
        </div>


        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
        <div className="services-process-card">
        <div className="services-process-card-body">
          <h4 className="services-process-card-title">Training and Documentation</h4>
          <div className="services-process-card-image" style={{color: "#213661"}}>
            {/* <i className="fas fa-laptop-code fa-5x"></i> */}
            <img src={training} alt="process_image"/>
          </div>
          <p className="services-process-card-text ">We deliver extensive training to help you and your team operate the new website efficiently. Furthermore, we provide complete documentation that includes both user guides and technical information.</p>
        </div>
      </div>
        </div>

        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
        <div className="services-process-card">
        <div className="services-process-card-body">
          <h4 className="services-process-card-title">Maintenance and Support</h4>
          <div className="services-process-card-image" style={{color: "#213661"}}>
            {/* <i className="fas fa-laptop-code fa-5x"></i> */}
            <img src={maintenance} alt="process_image"/>
          </div>
          <p className="services-process-card-text ">After deployment, our support doesn’t end. We provide regular maintenance and support to keep your website updated and running efficiently. Our team is on standby 24/7 to address any issues, execute updates, and make sure the website aligns with your changing business requirements.</p>
        </div>
      </div>
        </div>

        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
        <div className="services-process-card">
        <div className="services-process-card-body">
          <h4 className="services-process-card-title">Continuous Improvement</h4>
          <div className="services-process-card-image" style={{color: "#213661"}}>
            {/* <i className="fas fa-laptop-code fa-5x"></i> */}
            <img src={improvement} alt="process_image"/>
          </div>
          <p className="services-process-card-text ">Our focus is on constant improvement. We partner with you to leverage user feedback and adapt to new business requirements, making ongoing enhancements to your website to ensure it remains effective and in sync with your long-term vision.</p>
        </div>
      </div>
        </div>

        </div>
        </div>

            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>


      {/* ===================== */}
      {/* API Services Modal */}
      {/* ===================== */}

      <div
        className="modal fade modal-xl"
        id="exampleModalapi"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
        <div className="modal-content p-4">
            <div className="modal-header">
              {/* <h1
                className="modal-title fs-5 project-details-information-title"
                id="exampleModalLabel"
              >
               Modal Title API
              </h1> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
           

           <div className='services-details-expertise-div'>
           <h3>Explore Our Service <span>Expertise</span></h3>
           <p className='services-details-expertise-div-p'>See How Our Expertise Transforms Challenges into Opportunities Through Tailored Service Solutions</p>

           <div className='services-details-expertise'>
               <div className='row gx-5 gy-5'>
                   <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                       <div className='services-details-expertise-title' onClick={handleCustomWebClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Custom Mobile App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleMultiVendorClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Cross-Platform App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>


                       <div className='services-details-expertise-title' onClick={handleCMSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Native App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleCRMClick}>
                       <p> <i className="fa-solid fa-cloud"></i> UI/UX Design</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleLMSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Mobile App Testing </p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleERPClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Maintenance and Support</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handlePOSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> App Store Optimization (ASO)</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleWebDesignClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Integration with Wearable Devices</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleMaintenanceClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Enterprise Mobility Solutions</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleUIUXClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Cloud-Based Mobile Apps</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                   </div>

                   <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>

                   {
   activeButton === 'handleCustomWeb' && 
   <div className='services-details-expertise-description'>
   <h3> Custom Mobile App Development</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> User Interface (UI) Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience (UX) Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> Functionality and Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Backend Development </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Platform Development </p>
   <p><i className="fa-solid fa-circle-dot"></i> App Store Integration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Testing and Quality Assurance </p>
   <p><i className="fa-solid fa-circle-dot"></i> Geolocation Services </p>
   <p><i className="fa-solid fa-circle-dot"></i> Custom APIs </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Feedback and Support</p>
                           </div>
   }

                   {
   activeButton === 'handleMultiVendor' && 
   <div className='services-details-expertise-description'>
   <h3>Cross-Platform App Development</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Unified Codebase</p>
   <p><i className="fa-solid fa-circle-dot"></i> Frameworks and Tools</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Interface (UI) Design </p>
   <p><i className="fa-solid fa-circle-dot"></i> Order and Payment Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization</p>
   <p><i className="fa-solid fa-circle-dot"></i> Device and Platform Integration</p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Platform Libraries</p>
   <p><i className="fa-solid fa-circle-dot"></i> Testing and Debugging</p>
   <p><i className="fa-solid fa-circle-dot"></i> Deployment and Distribution</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience (UX)</p>
                           </div>
 }


 
{
   activeButton === 'handleCMS' && 
   <div className='services-details-expertise-description'>
   <h3>  Content Management Systems (CMS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> User Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Creation and Editing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Organization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Template and Design Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> SEO and Metadata </p>
   <p><i className="fa-solid fa-circle-dot"></i> Search and Navigation</p>
   <p><i className="fa-solid fa-circle-dot"></i> Multimedia Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting</p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features</p>
   <p><i className="fa-solid fa-circle-dot"></i> Multi-language and Localization</p>
   <p><i className="fa-solid fa-circle-dot"></i> API and Integrations</p>
                           </div>
   }


   
{
   activeButton === 'handleCRM' && 
   <div className='services-details-expertise-description'>
   <h3> Customer Relationship Management (CRM)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Contact Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Lead and Opportunity Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Sales Automation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Service and Support </p>
   <p><i className="fa-solid fa-circle-dot"></i> Email and Communication Tools </p>
   <p><i className="fa-solid fa-circle-dot"></i> Reporting and Analytics </p>
   <p><i className="fa-solid fa-circle-dot"></i> Task and Activity Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> Data Management and Security </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customization and Flexibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> Contract and Agreement Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration with Financial Systems </p>
                           </div>
   }


   
{
   activeButton === 'handleLMS' && 
   <div className='services-details-expertise-description'>
   <h3> Learning Management System (LMS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Course Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Delivery </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Assessment and Testing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Tracking and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Communication and Collaboration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Certification and Accreditation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Mobile Access </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customization and Branding </p>
                           </div>
   }


   
{
   activeButton === 'handleERP' && 
   <div className='services-details-expertise-description'>
   <h3> Enterprise Resource Planning (ERP)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Financial Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Human Resources Management (HRM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Supply Chain Management (SCM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Manufacturing and Production</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Relationship Management (CRM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Project Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Sales and Distribution </p>
   <p><i className="fa-solid fa-circle-dot"></i> Business Intelligence and Reporting</p>
   <p><i className="fa-solid fa-circle-dot"></i> Procurement and Vendor Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Asset Managementy</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customizable User Interface</p>
                           </div>
   }


   
{
   activeButton === 'handlePOS' && 
<div className='services-details-expertise-description'>
   <h3> Point of Sale (POS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Sales and Transactions</p>
   <p><i className="fa-solid fa-circle-dot"></i> Inventory Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Product Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Reporting and Analytics </p>
   <p><i className="fa-solid fa-circle-dot"></i> Employee Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Interface and Usability </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Advanced Reporting and Analytics</p>
                           </div>
   }


   
{
   activeButton === 'handleWebDesign' && 
   <div className='services-details-expertise-description'>
   <h3> Responsive Web Design</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Fluid Grid Layouts</p>
   <p><i className="fa-solid fa-circle-dot"></i> Flexible Images and Media </p>
   <p><i className="fa-solid fa-circle-dot"></i> Media Queries </p>
   <p><i className="fa-solid fa-circle-dot"></i> Responsive Typography </p>
   <p><i className="fa-solid fa-circle-dot"></i> Adaptive Navigation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Viewport Meta Tag </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Browser Compatibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> Accessibility Considerations </p>
   <p><i className="fa-solid fa-circle-dot"></i> Custom Breakpoints</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience Testing</p>
                           </div>
   }


   
{
   activeButton === 'handleMaintenance' && 
   <div className='services-details-expertise-description'>
   <h3> Website Maintenance and Support</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Regular Updates</p>
   <p><i className="fa-solid fa-circle-dot"></i> Backups and Recovery</p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Monitoring </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Uptime Monitoring </p>
   <p><i className="fa-solid fa-circle-dot"></i> Bug Fixes and Troubleshooting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience Improvements </p>
   <p><i className="fa-solid fa-circle-dot"></i> Compliance Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Support Integration</p>
                           </div>
   }


   
{
   activeButton === 'handleUIUX' && 
   <div className='services-details-expertise-description'>
   <h3>  UI/UX Design</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Visual Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> Layout and Structure </p>
   <p><i className="fa-solid fa-circle-dot"></i> Navigation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Interactive Elements</p>
   <p><i className="fa-solid fa-circle-dot"></i> Feedback and Communication</p>
   <p><i className="fa-solid fa-circle-dot"></i> Accessibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Research and Personas</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Flow and Journey Mapping </p>
   <p><i className="fa-solid fa-circle-dot"></i> Support and Communication</p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance and Efficiency</p>
   <p><i className="fa-solid fa-circle-dot"></i> Responsiveness and Adaptability</p>
                           </div>
   }

{
   activeButton === 'handleSEO' && 
<div className='services-details-expertise-description'>
   <h3> SEO and Digital Marketing</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> On-Page SEO</p>
   <p><i className="fa-solid fa-circle-dot"></i> Technical SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Off-Page SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Local SEO Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Marketing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Social Media Marketing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Pay-Per-Click (PPC) Advertising </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Insights</p>
   <p><i className="fa-solid fa-circle-dot"></i> Webinar and Event Marketing </p>
                           </div>
   }


{
   activeButton === 'handleDeploy' && 
   <div className='services-details-expertise-description'>
   <h3>  Server Deploy and Domain Management</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Server Provisioning</p>
   <p><i className="fa-solid fa-circle-dot"></i> Server Configuration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Automation and Orchestration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Scalability and Load Balancing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security </p>
   <p><i className="fa-solid fa-circle-dot"></i> Monitoring and Performance</p>
   <p><i className="fa-solid fa-circle-dot"></i> Backup and Recovery</p>
   <p><i className="fa-solid fa-circle-dot"></i> Deployment Pipelines</p>
   <p><i className="fa-solid fa-circle-dot"></i> Access Control</p>
   <p><i className="fa-solid fa-circle-dot"></i>Traffic Analytics</p>
   <p><i className="fa-solid fa-circle-dot"></i> Compliance and Legal</p>
                           </div>
   }


                     
                   </div>

               </div>

           </div>

           </div>
       
       <div className='services-details-development-process-div'>
       <h3>Empower Your Business Potential Through Our Mobile App Development.</h3>
       <p className='services-details-development-process-div-p'>Creating and delivering smart solutions to help your business succeed in the future.</p>

       <div className='row gx-3 gy-3'>
       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Initial Consultation</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={consultation} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our first step is to conduct a consultation to understand your business goals and vision, which helps us gather the necessary details to create a customized web development plan aligned with your objectives.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
       <h4 className="services-process-card-title">Requirement Analysis and Project Planning</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={projectPlanning} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We start with a comprehensive analysis of your requirements and develop a detailed project plan that specifies the scope, sets timelines, and allocates resources to ensure a well-structured and efficient development workflow.</p>
       </div>
     </div>
       </div>



       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Design Creation and Prototype Testing</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={prototype} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We produce wireframes and prototypes to showcase your website’s design and functionality. Our team prioritizes user experience (UX) and user interface (UI) to ensure the site is both easy to use and visually appealing. You will have the opportunity to review and offer feedback for design improvements.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Development</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={development} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our expert developers transform the design into a functional website by crafting clean, efficient, and scalable code. Utilizing the latest technologies and best practices, we ensure your site is robust, secure, and performs optimally on all devices.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Testing and Quality Assurance</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={testing} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Quality is our highest concern. Our QA team rigorously tests the website to detect and address any issues. We undertake various testing methods—such as functional, performance, security, and user acceptance testing (UAT)—to ensure the website upholds the highest quality standards.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Deployment</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={deployment} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">After the website clears all testing phases, we proceed with deployment. Our team facilitates a seamless transition to the live environment and offers support during the initial launch to address any immediate concerns and ensure smooth operation.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Training and Documentation</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={training} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We deliver extensive training to help you and your team operate the new website efficiently. Furthermore, we provide complete documentation that includes both user guides and technical information.</p>
       </div>
     </div>
       </div>

       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Maintenance and Support</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={maintenance} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">After deployment, our support doesn’t end. We provide regular maintenance and support to keep your website updated and running efficiently. Our team is on standby 24/7 to address any issues, execute updates, and make sure the website aligns with your changing business requirements.</p>
       </div>
     </div>
       </div>

       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Continuous Improvement</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={improvement} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our focus is on constant improvement. We partner with you to leverage user feedback and adapt to new business requirements, making ongoing enhancements to your website to ensure it remains effective and in sync with your long-term vision.</p>
       </div>
     </div>
       </div>

       </div>
       </div>

           </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>


         {/* ===================== */}
      {/* Software QA Services Modal */}
      {/* ===================== */}

      <div
        className="modal fade modal-xl"
        id="exampleModalsqa"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
        <div className="modal-content p-4">
            <div className="modal-header">
              {/* <h1
                className="modal-title fs-5 project-details-information-title"
                id="exampleModalLabel"
              >
               Modal Title SQA
              </h1> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
           

           <div className='services-details-expertise-div'>
           <h3>Explore Our Service <span>Expertise</span></h3>
           <p className='services-details-expertise-div-p'>See How Our Expertise Transforms Challenges into Opportunities Through Tailored Service Solutions</p>

           <div className='services-details-expertise'>
               <div className='row gx-5 gy-5'>
                   <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                       <div className='services-details-expertise-title' onClick={handleCustomWebClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Custom Mobile App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleMultiVendorClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Cross-Platform App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>


                       <div className='services-details-expertise-title' onClick={handleCMSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Native App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleCRMClick}>
                       <p> <i className="fa-solid fa-cloud"></i> UI/UX Design</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleLMSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Mobile App Testing </p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleERPClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Maintenance and Support</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handlePOSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> App Store Optimization (ASO)</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleWebDesignClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Integration with Wearable Devices</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleMaintenanceClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Enterprise Mobility Solutions</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleUIUXClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Cloud-Based Mobile Apps</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                   </div>

                   <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>

                   {
   activeButton === 'handleCustomWeb' && 
   <div className='services-details-expertise-description'>
   <h3> Custom Mobile App Development</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> User Interface (UI) Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience (UX) Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> Functionality and Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Backend Development </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Platform Development </p>
   <p><i className="fa-solid fa-circle-dot"></i> App Store Integration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Testing and Quality Assurance </p>
   <p><i className="fa-solid fa-circle-dot"></i> Geolocation Services </p>
   <p><i className="fa-solid fa-circle-dot"></i> Custom APIs </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Feedback and Support</p>
                           </div>
   }

                   {
   activeButton === 'handleMultiVendor' && 
   <div className='services-details-expertise-description'>
   <h3>Cross-Platform App Development</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Unified Codebase</p>
   <p><i className="fa-solid fa-circle-dot"></i> Frameworks and Tools</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Interface (UI) Design </p>
   <p><i className="fa-solid fa-circle-dot"></i> Order and Payment Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization</p>
   <p><i className="fa-solid fa-circle-dot"></i> Device and Platform Integration</p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Platform Libraries</p>
   <p><i className="fa-solid fa-circle-dot"></i> Testing and Debugging</p>
   <p><i className="fa-solid fa-circle-dot"></i> Deployment and Distribution</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience (UX)</p>
                           </div>
 }


 
{
   activeButton === 'handleCMS' && 
   <div className='services-details-expertise-description'>
   <h3>  Content Management Systems (CMS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> User Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Creation and Editing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Organization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Template and Design Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> SEO and Metadata </p>
   <p><i className="fa-solid fa-circle-dot"></i> Search and Navigation</p>
   <p><i className="fa-solid fa-circle-dot"></i> Multimedia Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting</p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features</p>
   <p><i className="fa-solid fa-circle-dot"></i> Multi-language and Localization</p>
   <p><i className="fa-solid fa-circle-dot"></i> API and Integrations</p>
                           </div>
   }


   
{
   activeButton === 'handleCRM' && 
   <div className='services-details-expertise-description'>
   <h3> Customer Relationship Management (CRM)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Contact Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Lead and Opportunity Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Sales Automation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Service and Support </p>
   <p><i className="fa-solid fa-circle-dot"></i> Email and Communication Tools </p>
   <p><i className="fa-solid fa-circle-dot"></i> Reporting and Analytics </p>
   <p><i className="fa-solid fa-circle-dot"></i> Task and Activity Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> Data Management and Security </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customization and Flexibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> Contract and Agreement Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration with Financial Systems </p>
                           </div>
   }


   
{
   activeButton === 'handleLMS' && 
   <div className='services-details-expertise-description'>
   <h3> Learning Management System (LMS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Course Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Delivery </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Assessment and Testing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Tracking and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Communication and Collaboration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Certification and Accreditation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Mobile Access </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customization and Branding </p>
                           </div>
   }


   
{
   activeButton === 'handleERP' && 
   <div className='services-details-expertise-description'>
   <h3> Enterprise Resource Planning (ERP)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Financial Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Human Resources Management (HRM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Supply Chain Management (SCM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Manufacturing and Production</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Relationship Management (CRM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Project Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Sales and Distribution </p>
   <p><i className="fa-solid fa-circle-dot"></i> Business Intelligence and Reporting</p>
   <p><i className="fa-solid fa-circle-dot"></i> Procurement and Vendor Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Asset Managementy</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customizable User Interface</p>
                           </div>
   }


   
{
   activeButton === 'handlePOS' && 
<div className='services-details-expertise-description'>
   <h3> Point of Sale (POS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Sales and Transactions</p>
   <p><i className="fa-solid fa-circle-dot"></i> Inventory Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Product Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Reporting and Analytics </p>
   <p><i className="fa-solid fa-circle-dot"></i> Employee Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Interface and Usability </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Advanced Reporting and Analytics</p>
                           </div>
   }


   
{
   activeButton === 'handleWebDesign' && 
   <div className='services-details-expertise-description'>
   <h3> Responsive Web Design</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Fluid Grid Layouts</p>
   <p><i className="fa-solid fa-circle-dot"></i> Flexible Images and Media </p>
   <p><i className="fa-solid fa-circle-dot"></i> Media Queries </p>
   <p><i className="fa-solid fa-circle-dot"></i> Responsive Typography </p>
   <p><i className="fa-solid fa-circle-dot"></i> Adaptive Navigation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Viewport Meta Tag </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Browser Compatibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> Accessibility Considerations </p>
   <p><i className="fa-solid fa-circle-dot"></i> Custom Breakpoints</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience Testing</p>
                           </div>
   }


   
{
   activeButton === 'handleMaintenance' && 
   <div className='services-details-expertise-description'>
   <h3> Website Maintenance and Support</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Regular Updates</p>
   <p><i className="fa-solid fa-circle-dot"></i> Backups and Recovery</p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Monitoring </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Uptime Monitoring </p>
   <p><i className="fa-solid fa-circle-dot"></i> Bug Fixes and Troubleshooting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience Improvements </p>
   <p><i className="fa-solid fa-circle-dot"></i> Compliance Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Support Integration</p>
                           </div>
   }


   
{
   activeButton === 'handleUIUX' && 
   <div className='services-details-expertise-description'>
   <h3>  UI/UX Design</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Visual Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> Layout and Structure </p>
   <p><i className="fa-solid fa-circle-dot"></i> Navigation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Interactive Elements</p>
   <p><i className="fa-solid fa-circle-dot"></i> Feedback and Communication</p>
   <p><i className="fa-solid fa-circle-dot"></i> Accessibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Research and Personas</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Flow and Journey Mapping </p>
   <p><i className="fa-solid fa-circle-dot"></i> Support and Communication</p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance and Efficiency</p>
   <p><i className="fa-solid fa-circle-dot"></i> Responsiveness and Adaptability</p>
                           </div>
   }

{
   activeButton === 'handleSEO' && 
<div className='services-details-expertise-description'>
   <h3> SEO and Digital Marketing</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> On-Page SEO</p>
   <p><i className="fa-solid fa-circle-dot"></i> Technical SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Off-Page SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Local SEO Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Marketing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Social Media Marketing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Pay-Per-Click (PPC) Advertising </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Insights</p>
   <p><i className="fa-solid fa-circle-dot"></i> Webinar and Event Marketing </p>
                           </div>
   }


{
   activeButton === 'handleDeploy' && 
   <div className='services-details-expertise-description'>
   <h3>  Server Deploy and Domain Management</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Server Provisioning</p>
   <p><i className="fa-solid fa-circle-dot"></i> Server Configuration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Automation and Orchestration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Scalability and Load Balancing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security </p>
   <p><i className="fa-solid fa-circle-dot"></i> Monitoring and Performance</p>
   <p><i className="fa-solid fa-circle-dot"></i> Backup and Recovery</p>
   <p><i className="fa-solid fa-circle-dot"></i> Deployment Pipelines</p>
   <p><i className="fa-solid fa-circle-dot"></i> Access Control</p>
   <p><i className="fa-solid fa-circle-dot"></i>Traffic Analytics</p>
   <p><i className="fa-solid fa-circle-dot"></i> Compliance and Legal</p>
                           </div>
   }


                     
                   </div>

               </div>

           </div>

           </div>
       
       <div className='services-details-development-process-div'>
       <h3>Empower Your Business Potential Through Our Mobile App Development.</h3>
       <p className='services-details-development-process-div-p'>Creating and delivering smart solutions to help your business succeed in the future.</p>

       <div className='row gx-3 gy-3'>
       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Initial Consultation</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={consultation} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our first step is to conduct a consultation to understand your business goals and vision, which helps us gather the necessary details to create a customized web development plan aligned with your objectives.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
       <h4 className="services-process-card-title">Requirement Analysis and Project Planning</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={projectPlanning} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We start with a comprehensive analysis of your requirements and develop a detailed project plan that specifies the scope, sets timelines, and allocates resources to ensure a well-structured and efficient development workflow.</p>
       </div>
     </div>
       </div>



       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Design Creation and Prototype Testing</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={prototype} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We produce wireframes and prototypes to showcase your website’s design and functionality. Our team prioritizes user experience (UX) and user interface (UI) to ensure the site is both easy to use and visually appealing. You will have the opportunity to review and offer feedback for design improvements.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Development</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={development} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our expert developers transform the design into a functional website by crafting clean, efficient, and scalable code. Utilizing the latest technologies and best practices, we ensure your site is robust, secure, and performs optimally on all devices.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Testing and Quality Assurance</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={testing} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Quality is our highest concern. Our QA team rigorously tests the website to detect and address any issues. We undertake various testing methods—such as functional, performance, security, and user acceptance testing (UAT)—to ensure the website upholds the highest quality standards.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Deployment</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={deployment} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">After the website clears all testing phases, we proceed with deployment. Our team facilitates a seamless transition to the live environment and offers support during the initial launch to address any immediate concerns and ensure smooth operation.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Training and Documentation</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={training} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We deliver extensive training to help you and your team operate the new website efficiently. Furthermore, we provide complete documentation that includes both user guides and technical information.</p>
       </div>
     </div>
       </div>

       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Maintenance and Support</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={maintenance} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">After deployment, our support doesn’t end. We provide regular maintenance and support to keep your website updated and running efficiently. Our team is on standby 24/7 to address any issues, execute updates, and make sure the website aligns with your changing business requirements.</p>
       </div>
     </div>
       </div>

       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Continuous Improvement</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={improvement} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our focus is on constant improvement. We partner with you to leverage user feedback and adapt to new business requirements, making ongoing enhancements to your website to ensure it remains effective and in sync with your long-term vision.</p>
       </div>
     </div>
       </div>

       </div>
       </div>

           </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

         {/* ===================== */}
      {/* Automation Services Modal */}
      {/* ===================== */}

      <div
        className="modal fade modal-xl"
        id="exampleModalbot"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
        <div className="modal-content p-4">
            <div className="modal-header">
              {/* <h1
                className="modal-title fs-5 project-details-information-title"
                id="exampleModalLabel"
              >
               Modal Title Automation
              </h1> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
           

           <div className='services-details-expertise-div'>
           <h3>Explore Our Service <span>Expertise</span></h3>
           <p className='services-details-expertise-div-p'>See How Our Expertise Transforms Challenges into Opportunities Through Tailored Service Solutions</p>

           <div className='services-details-expertise'>
               <div className='row gx-5 gy-5'>
                   <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                       <div className='services-details-expertise-title' onClick={handleCustomWebClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Custom Mobile App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleMultiVendorClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Cross-Platform App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>


                       <div className='services-details-expertise-title' onClick={handleCMSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Native App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleCRMClick}>
                       <p> <i className="fa-solid fa-cloud"></i> UI/UX Design</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleLMSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Mobile App Testing </p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleERPClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Maintenance and Support</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handlePOSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> App Store Optimization (ASO)</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleWebDesignClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Integration with Wearable Devices</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleMaintenanceClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Enterprise Mobility Solutions</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleUIUXClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Cloud-Based Mobile Apps</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                   </div>

                   <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>

                   {
   activeButton === 'handleCustomWeb' && 
   <div className='services-details-expertise-description'>
   <h3> Custom Mobile App Development</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> User Interface (UI) Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience (UX) Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> Functionality and Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Backend Development </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Platform Development </p>
   <p><i className="fa-solid fa-circle-dot"></i> App Store Integration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Testing and Quality Assurance </p>
   <p><i className="fa-solid fa-circle-dot"></i> Geolocation Services </p>
   <p><i className="fa-solid fa-circle-dot"></i> Custom APIs </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Feedback and Support</p>
                           </div>
   }

                   {
   activeButton === 'handleMultiVendor' && 
   <div className='services-details-expertise-description'>
   <h3>Cross-Platform App Development</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Unified Codebase</p>
   <p><i className="fa-solid fa-circle-dot"></i> Frameworks and Tools</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Interface (UI) Design </p>
   <p><i className="fa-solid fa-circle-dot"></i> Order and Payment Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization</p>
   <p><i className="fa-solid fa-circle-dot"></i> Device and Platform Integration</p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Platform Libraries</p>
   <p><i className="fa-solid fa-circle-dot"></i> Testing and Debugging</p>
   <p><i className="fa-solid fa-circle-dot"></i> Deployment and Distribution</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience (UX)</p>
                           </div>
 }


 
{
   activeButton === 'handleCMS' && 
   <div className='services-details-expertise-description'>
   <h3>  Content Management Systems (CMS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> User Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Creation and Editing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Organization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Template and Design Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> SEO and Metadata </p>
   <p><i className="fa-solid fa-circle-dot"></i> Search and Navigation</p>
   <p><i className="fa-solid fa-circle-dot"></i> Multimedia Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting</p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features</p>
   <p><i className="fa-solid fa-circle-dot"></i> Multi-language and Localization</p>
   <p><i className="fa-solid fa-circle-dot"></i> API and Integrations</p>
                           </div>
   }


   
{
   activeButton === 'handleCRM' && 
   <div className='services-details-expertise-description'>
   <h3> Customer Relationship Management (CRM)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Contact Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Lead and Opportunity Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Sales Automation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Service and Support </p>
   <p><i className="fa-solid fa-circle-dot"></i> Email and Communication Tools </p>
   <p><i className="fa-solid fa-circle-dot"></i> Reporting and Analytics </p>
   <p><i className="fa-solid fa-circle-dot"></i> Task and Activity Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> Data Management and Security </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customization and Flexibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> Contract and Agreement Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration with Financial Systems </p>
                           </div>
   }


   
{
   activeButton === 'handleLMS' && 
   <div className='services-details-expertise-description'>
   <h3> Learning Management System (LMS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Course Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Delivery </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Assessment and Testing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Tracking and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Communication and Collaboration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Certification and Accreditation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Mobile Access </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customization and Branding </p>
                           </div>
   }


   
{
   activeButton === 'handleERP' && 
   <div className='services-details-expertise-description'>
   <h3> Enterprise Resource Planning (ERP)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Financial Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Human Resources Management (HRM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Supply Chain Management (SCM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Manufacturing and Production</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Relationship Management (CRM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Project Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Sales and Distribution </p>
   <p><i className="fa-solid fa-circle-dot"></i> Business Intelligence and Reporting</p>
   <p><i className="fa-solid fa-circle-dot"></i> Procurement and Vendor Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Asset Managementy</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customizable User Interface</p>
                           </div>
   }


   
{
   activeButton === 'handlePOS' && 
<div className='services-details-expertise-description'>
   <h3> Point of Sale (POS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Sales and Transactions</p>
   <p><i className="fa-solid fa-circle-dot"></i> Inventory Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Product Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Reporting and Analytics </p>
   <p><i className="fa-solid fa-circle-dot"></i> Employee Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Interface and Usability </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Advanced Reporting and Analytics</p>
                           </div>
   }


   
{
   activeButton === 'handleWebDesign' && 
   <div className='services-details-expertise-description'>
   <h3> Responsive Web Design</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Fluid Grid Layouts</p>
   <p><i className="fa-solid fa-circle-dot"></i> Flexible Images and Media </p>
   <p><i className="fa-solid fa-circle-dot"></i> Media Queries </p>
   <p><i className="fa-solid fa-circle-dot"></i> Responsive Typography </p>
   <p><i className="fa-solid fa-circle-dot"></i> Adaptive Navigation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Viewport Meta Tag </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Browser Compatibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> Accessibility Considerations </p>
   <p><i className="fa-solid fa-circle-dot"></i> Custom Breakpoints</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience Testing</p>
                           </div>
   }


   
{
   activeButton === 'handleMaintenance' && 
   <div className='services-details-expertise-description'>
   <h3> Website Maintenance and Support</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Regular Updates</p>
   <p><i className="fa-solid fa-circle-dot"></i> Backups and Recovery</p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Monitoring </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Uptime Monitoring </p>
   <p><i className="fa-solid fa-circle-dot"></i> Bug Fixes and Troubleshooting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience Improvements </p>
   <p><i className="fa-solid fa-circle-dot"></i> Compliance Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Support Integration</p>
                           </div>
   }


   
{
   activeButton === 'handleUIUX' && 
   <div className='services-details-expertise-description'>
   <h3>  UI/UX Design</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Visual Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> Layout and Structure </p>
   <p><i className="fa-solid fa-circle-dot"></i> Navigation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Interactive Elements</p>
   <p><i className="fa-solid fa-circle-dot"></i> Feedback and Communication</p>
   <p><i className="fa-solid fa-circle-dot"></i> Accessibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Research and Personas</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Flow and Journey Mapping </p>
   <p><i className="fa-solid fa-circle-dot"></i> Support and Communication</p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance and Efficiency</p>
   <p><i className="fa-solid fa-circle-dot"></i> Responsiveness and Adaptability</p>
                           </div>
   }

{
   activeButton === 'handleSEO' && 
<div className='services-details-expertise-description'>
   <h3> SEO and Digital Marketing</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> On-Page SEO</p>
   <p><i className="fa-solid fa-circle-dot"></i> Technical SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Off-Page SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Local SEO Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Marketing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Social Media Marketing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Pay-Per-Click (PPC) Advertising </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Insights</p>
   <p><i className="fa-solid fa-circle-dot"></i> Webinar and Event Marketing </p>
                           </div>
   }


{
   activeButton === 'handleDeploy' && 
   <div className='services-details-expertise-description'>
   <h3>  Server Deploy and Domain Management</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Server Provisioning</p>
   <p><i className="fa-solid fa-circle-dot"></i> Server Configuration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Automation and Orchestration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Scalability and Load Balancing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security </p>
   <p><i className="fa-solid fa-circle-dot"></i> Monitoring and Performance</p>
   <p><i className="fa-solid fa-circle-dot"></i> Backup and Recovery</p>
   <p><i className="fa-solid fa-circle-dot"></i> Deployment Pipelines</p>
   <p><i className="fa-solid fa-circle-dot"></i> Access Control</p>
   <p><i className="fa-solid fa-circle-dot"></i>Traffic Analytics</p>
   <p><i className="fa-solid fa-circle-dot"></i> Compliance and Legal</p>
                           </div>
   }


                     
                   </div>

               </div>

           </div>

           </div>
       
       <div className='services-details-development-process-div'>
       <h3>Empower Your Business Potential Through Our Mobile App Development.</h3>
       <p className='services-details-development-process-div-p'>Creating and delivering smart solutions to help your business succeed in the future.</p>

       <div className='row gx-3 gy-3'>
       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Initial Consultation</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={consultation} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our first step is to conduct a consultation to understand your business goals and vision, which helps us gather the necessary details to create a customized web development plan aligned with your objectives.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
       <h4 className="services-process-card-title">Requirement Analysis and Project Planning</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={projectPlanning} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We start with a comprehensive analysis of your requirements and develop a detailed project plan that specifies the scope, sets timelines, and allocates resources to ensure a well-structured and efficient development workflow.</p>
       </div>
     </div>
       </div>



       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Design Creation and Prototype Testing</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={prototype} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We produce wireframes and prototypes to showcase your website’s design and functionality. Our team prioritizes user experience (UX) and user interface (UI) to ensure the site is both easy to use and visually appealing. You will have the opportunity to review and offer feedback for design improvements.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Development</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={development} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our expert developers transform the design into a functional website by crafting clean, efficient, and scalable code. Utilizing the latest technologies and best practices, we ensure your site is robust, secure, and performs optimally on all devices.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Testing and Quality Assurance</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={testing} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Quality is our highest concern. Our QA team rigorously tests the website to detect and address any issues. We undertake various testing methods—such as functional, performance, security, and user acceptance testing (UAT)—to ensure the website upholds the highest quality standards.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Deployment</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={deployment} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">After the website clears all testing phases, we proceed with deployment. Our team facilitates a seamless transition to the live environment and offers support during the initial launch to address any immediate concerns and ensure smooth operation.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Training and Documentation</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={training} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We deliver extensive training to help you and your team operate the new website efficiently. Furthermore, we provide complete documentation that includes both user guides and technical information.</p>
       </div>
     </div>
       </div>

       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Maintenance and Support</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={maintenance} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">After deployment, our support doesn’t end. We provide regular maintenance and support to keep your website updated and running efficiently. Our team is on standby 24/7 to address any issues, execute updates, and make sure the website aligns with your changing business requirements.</p>
       </div>
     </div>
       </div>

       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Continuous Improvement</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={improvement} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our focus is on constant improvement. We partner with you to leverage user feedback and adapt to new business requirements, making ongoing enhancements to your website to ensure it remains effective and in sync with your long-term vision.</p>
       </div>
     </div>
       </div>

       </div>
       </div>

           </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>


         {/* ===================== */}
      {/* UI UX Services Modal */}
      {/* ===================== */}

      <div
        className="modal fade modal-xl"
        id="exampleModalUiUx"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
        <div className="modal-content p-4">
            <div className="modal-header">
              {/* <h1
                className="modal-title fs-5 project-details-information-title"
                id="exampleModalLabel"
              >
               Modal Title UI UX Design
              </h1> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
           

           <div className='services-details-expertise-div'>
           <h3>Explore Our Service <span>Expertise</span></h3>
           <p className='services-details-expertise-div-p'>See How Our Expertise Transforms Challenges into Opportunities Through Tailored Service Solutions</p>

           <div className='services-details-expertise'>
               <div className='row gx-5 gy-5'>
                   <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                       <div className='services-details-expertise-title' onClick={handleCustomWebClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Custom Mobile App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleMultiVendorClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Cross-Platform App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>


                       <div className='services-details-expertise-title' onClick={handleCMSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Native App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleCRMClick}>
                       <p> <i className="fa-solid fa-cloud"></i> UI/UX Design</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleLMSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Mobile App Testing </p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleERPClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Maintenance and Support</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handlePOSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> App Store Optimization (ASO)</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleWebDesignClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Integration with Wearable Devices</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleMaintenanceClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Enterprise Mobility Solutions</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleUIUXClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Cloud-Based Mobile Apps</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                   </div>

                   <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>

                   {
   activeButton === 'handleCustomWeb' && 
   <div className='services-details-expertise-description'>
   <h3> Custom Mobile App Development</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> User Interface (UI) Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience (UX) Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> Functionality and Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Backend Development </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Platform Development </p>
   <p><i className="fa-solid fa-circle-dot"></i> App Store Integration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Testing and Quality Assurance </p>
   <p><i className="fa-solid fa-circle-dot"></i> Geolocation Services </p>
   <p><i className="fa-solid fa-circle-dot"></i> Custom APIs </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Feedback and Support</p>
                           </div>
   }

                   {
   activeButton === 'handleMultiVendor' && 
   <div className='services-details-expertise-description'>
   <h3>Cross-Platform App Development</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Unified Codebase</p>
   <p><i className="fa-solid fa-circle-dot"></i> Frameworks and Tools</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Interface (UI) Design </p>
   <p><i className="fa-solid fa-circle-dot"></i> Order and Payment Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization</p>
   <p><i className="fa-solid fa-circle-dot"></i> Device and Platform Integration</p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Platform Libraries</p>
   <p><i className="fa-solid fa-circle-dot"></i> Testing and Debugging</p>
   <p><i className="fa-solid fa-circle-dot"></i> Deployment and Distribution</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience (UX)</p>
                           </div>
 }


 
{
   activeButton === 'handleCMS' && 
   <div className='services-details-expertise-description'>
   <h3>  Content Management Systems (CMS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> User Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Creation and Editing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Organization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Template and Design Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> SEO and Metadata </p>
   <p><i className="fa-solid fa-circle-dot"></i> Search and Navigation</p>
   <p><i className="fa-solid fa-circle-dot"></i> Multimedia Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting</p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features</p>
   <p><i className="fa-solid fa-circle-dot"></i> Multi-language and Localization</p>
   <p><i className="fa-solid fa-circle-dot"></i> API and Integrations</p>
                           </div>
   }


   
{
   activeButton === 'handleCRM' && 
   <div className='services-details-expertise-description'>
   <h3> Customer Relationship Management (CRM)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Contact Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Lead and Opportunity Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Sales Automation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Service and Support </p>
   <p><i className="fa-solid fa-circle-dot"></i> Email and Communication Tools </p>
   <p><i className="fa-solid fa-circle-dot"></i> Reporting and Analytics </p>
   <p><i className="fa-solid fa-circle-dot"></i> Task and Activity Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> Data Management and Security </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customization and Flexibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> Contract and Agreement Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration with Financial Systems </p>
                           </div>
   }


   
{
   activeButton === 'handleLMS' && 
   <div className='services-details-expertise-description'>
   <h3> Learning Management System (LMS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Course Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Delivery </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Assessment and Testing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Tracking and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Communication and Collaboration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Certification and Accreditation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Mobile Access </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customization and Branding </p>
                           </div>
   }


   
{
   activeButton === 'handleERP' && 
   <div className='services-details-expertise-description'>
   <h3> Enterprise Resource Planning (ERP)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Financial Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Human Resources Management (HRM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Supply Chain Management (SCM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Manufacturing and Production</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Relationship Management (CRM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Project Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Sales and Distribution </p>
   <p><i className="fa-solid fa-circle-dot"></i> Business Intelligence and Reporting</p>
   <p><i className="fa-solid fa-circle-dot"></i> Procurement and Vendor Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Asset Managementy</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customizable User Interface</p>
                           </div>
   }


   
{
   activeButton === 'handlePOS' && 
<div className='services-details-expertise-description'>
   <h3> Point of Sale (POS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Sales and Transactions</p>
   <p><i className="fa-solid fa-circle-dot"></i> Inventory Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Product Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Reporting and Analytics </p>
   <p><i className="fa-solid fa-circle-dot"></i> Employee Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Interface and Usability </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Advanced Reporting and Analytics</p>
                           </div>
   }


   
{
   activeButton === 'handleWebDesign' && 
   <div className='services-details-expertise-description'>
   <h3> Responsive Web Design</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Fluid Grid Layouts</p>
   <p><i className="fa-solid fa-circle-dot"></i> Flexible Images and Media </p>
   <p><i className="fa-solid fa-circle-dot"></i> Media Queries </p>
   <p><i className="fa-solid fa-circle-dot"></i> Responsive Typography </p>
   <p><i className="fa-solid fa-circle-dot"></i> Adaptive Navigation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Viewport Meta Tag </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Browser Compatibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> Accessibility Considerations </p>
   <p><i className="fa-solid fa-circle-dot"></i> Custom Breakpoints</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience Testing</p>
                           </div>
   }


   
{
   activeButton === 'handleMaintenance' && 
   <div className='services-details-expertise-description'>
   <h3> Website Maintenance and Support</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Regular Updates</p>
   <p><i className="fa-solid fa-circle-dot"></i> Backups and Recovery</p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Monitoring </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Uptime Monitoring </p>
   <p><i className="fa-solid fa-circle-dot"></i> Bug Fixes and Troubleshooting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience Improvements </p>
   <p><i className="fa-solid fa-circle-dot"></i> Compliance Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Support Integration</p>
                           </div>
   }


   
{
   activeButton === 'handleUIUX' && 
   <div className='services-details-expertise-description'>
   <h3>  UI/UX Design</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Visual Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> Layout and Structure </p>
   <p><i className="fa-solid fa-circle-dot"></i> Navigation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Interactive Elements</p>
   <p><i className="fa-solid fa-circle-dot"></i> Feedback and Communication</p>
   <p><i className="fa-solid fa-circle-dot"></i> Accessibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Research and Personas</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Flow and Journey Mapping </p>
   <p><i className="fa-solid fa-circle-dot"></i> Support and Communication</p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance and Efficiency</p>
   <p><i className="fa-solid fa-circle-dot"></i> Responsiveness and Adaptability</p>
                           </div>
   }

{
   activeButton === 'handleSEO' && 
<div className='services-details-expertise-description'>
   <h3> SEO and Digital Marketing</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> On-Page SEO</p>
   <p><i className="fa-solid fa-circle-dot"></i> Technical SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Off-Page SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Local SEO Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Marketing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Social Media Marketing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Pay-Per-Click (PPC) Advertising </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Insights</p>
   <p><i className="fa-solid fa-circle-dot"></i> Webinar and Event Marketing </p>
                           </div>
   }


{
   activeButton === 'handleDeploy' && 
   <div className='services-details-expertise-description'>
   <h3>  Server Deploy and Domain Management</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Server Provisioning</p>
   <p><i className="fa-solid fa-circle-dot"></i> Server Configuration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Automation and Orchestration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Scalability and Load Balancing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security </p>
   <p><i className="fa-solid fa-circle-dot"></i> Monitoring and Performance</p>
   <p><i className="fa-solid fa-circle-dot"></i> Backup and Recovery</p>
   <p><i className="fa-solid fa-circle-dot"></i> Deployment Pipelines</p>
   <p><i className="fa-solid fa-circle-dot"></i> Access Control</p>
   <p><i className="fa-solid fa-circle-dot"></i>Traffic Analytics</p>
   <p><i className="fa-solid fa-circle-dot"></i> Compliance and Legal</p>
                           </div>
   }


                     
                   </div>

               </div>

           </div>

           </div>
       
       <div className='services-details-development-process-div'>
       <h3>Empower Your Business Potential Through Our Mobile App Development.</h3>
       <p className='services-details-development-process-div-p'>Creating and delivering smart solutions to help your business succeed in the future.</p>

       <div className='row gx-3 gy-3'>
       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Initial Consultation</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={consultation} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our first step is to conduct a consultation to understand your business goals and vision, which helps us gather the necessary details to create a customized web development plan aligned with your objectives.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
       <h4 className="services-process-card-title">Requirement Analysis and Project Planning</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={projectPlanning} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We start with a comprehensive analysis of your requirements and develop a detailed project plan that specifies the scope, sets timelines, and allocates resources to ensure a well-structured and efficient development workflow.</p>
       </div>
     </div>
       </div>



       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Design Creation and Prototype Testing</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={prototype} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We produce wireframes and prototypes to showcase your website’s design and functionality. Our team prioritizes user experience (UX) and user interface (UI) to ensure the site is both easy to use and visually appealing. You will have the opportunity to review and offer feedback for design improvements.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Development</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={development} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our expert developers transform the design into a functional website by crafting clean, efficient, and scalable code. Utilizing the latest technologies and best practices, we ensure your site is robust, secure, and performs optimally on all devices.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Testing and Quality Assurance</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={testing} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Quality is our highest concern. Our QA team rigorously tests the website to detect and address any issues. We undertake various testing methods—such as functional, performance, security, and user acceptance testing (UAT)—to ensure the website upholds the highest quality standards.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Deployment</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={deployment} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">After the website clears all testing phases, we proceed with deployment. Our team facilitates a seamless transition to the live environment and offers support during the initial launch to address any immediate concerns and ensure smooth operation.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Training and Documentation</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={training} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We deliver extensive training to help you and your team operate the new website efficiently. Furthermore, we provide complete documentation that includes both user guides and technical information.</p>
       </div>
     </div>
       </div>

       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Maintenance and Support</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={maintenance} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">After deployment, our support doesn’t end. We provide regular maintenance and support to keep your website updated and running efficiently. Our team is on standby 24/7 to address any issues, execute updates, and make sure the website aligns with your changing business requirements.</p>
       </div>
     </div>
       </div>

       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Continuous Improvement</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={improvement} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our focus is on constant improvement. We partner with you to leverage user feedback and adapt to new business requirements, making ongoing enhancements to your website to ensure it remains effective and in sync with your long-term vision.</p>
       </div>
     </div>
       </div>

       </div>
       </div>

           </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>


              {/* ===================== */}
      {/* Graphics Services Modal */}
      {/* ===================== */}

      <div
        className="modal fade modal-xl"
        id="exampleModalGraphics"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
        <div className="modal-content p-4">
            <div className="modal-header">
              {/* <h1
                className="modal-title fs-5 project-details-information-title"
                id="exampleModalLabel"
              >
               Modal Title Graphics Design
              </h1> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
           

           <div className='services-details-expertise-div'>
           <h3>Explore Our Service <span>Expertise</span></h3>
           <p className='services-details-expertise-div-p'>See How Our Expertise Transforms Challenges into Opportunities Through Tailored Service Solutions</p>

           <div className='services-details-expertise'>
               <div className='row gx-5 gy-5'>
                   <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                       <div className='services-details-expertise-title' onClick={handleCustomWebClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Custom Mobile App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleMultiVendorClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Cross-Platform App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>


                       <div className='services-details-expertise-title' onClick={handleCMSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Native App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleCRMClick}>
                       <p> <i className="fa-solid fa-cloud"></i> UI/UX Design</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleLMSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Mobile App Testing </p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleERPClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Maintenance and Support</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handlePOSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> App Store Optimization (ASO)</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleWebDesignClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Integration with Wearable Devices</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleMaintenanceClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Enterprise Mobility Solutions</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleUIUXClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Cloud-Based Mobile Apps</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                   </div>

                   <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>

                   {
   activeButton === 'handleCustomWeb' && 
   <div className='services-details-expertise-description'>
   <h3> Custom Mobile App Development</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> User Interface (UI) Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience (UX) Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> Functionality and Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Backend Development </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Platform Development </p>
   <p><i className="fa-solid fa-circle-dot"></i> App Store Integration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Testing and Quality Assurance </p>
   <p><i className="fa-solid fa-circle-dot"></i> Geolocation Services </p>
   <p><i className="fa-solid fa-circle-dot"></i> Custom APIs </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Feedback and Support</p>
                           </div>
   }

                   {
   activeButton === 'handleMultiVendor' && 
   <div className='services-details-expertise-description'>
   <h3>Cross-Platform App Development</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Unified Codebase</p>
   <p><i className="fa-solid fa-circle-dot"></i> Frameworks and Tools</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Interface (UI) Design </p>
   <p><i className="fa-solid fa-circle-dot"></i> Order and Payment Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization</p>
   <p><i className="fa-solid fa-circle-dot"></i> Device and Platform Integration</p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Platform Libraries</p>
   <p><i className="fa-solid fa-circle-dot"></i> Testing and Debugging</p>
   <p><i className="fa-solid fa-circle-dot"></i> Deployment and Distribution</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience (UX)</p>
                           </div>
 }


 
{
   activeButton === 'handleCMS' && 
   <div className='services-details-expertise-description'>
   <h3>  Content Management Systems (CMS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> User Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Creation and Editing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Organization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Template and Design Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> SEO and Metadata </p>
   <p><i className="fa-solid fa-circle-dot"></i> Search and Navigation</p>
   <p><i className="fa-solid fa-circle-dot"></i> Multimedia Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting</p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features</p>
   <p><i className="fa-solid fa-circle-dot"></i> Multi-language and Localization</p>
   <p><i className="fa-solid fa-circle-dot"></i> API and Integrations</p>
                           </div>
   }


   
{
   activeButton === 'handleCRM' && 
   <div className='services-details-expertise-description'>
   <h3> Customer Relationship Management (CRM)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Contact Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Lead and Opportunity Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Sales Automation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Service and Support </p>
   <p><i className="fa-solid fa-circle-dot"></i> Email and Communication Tools </p>
   <p><i className="fa-solid fa-circle-dot"></i> Reporting and Analytics </p>
   <p><i className="fa-solid fa-circle-dot"></i> Task and Activity Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> Data Management and Security </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customization and Flexibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> Contract and Agreement Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration with Financial Systems </p>
                           </div>
   }


   
{
   activeButton === 'handleLMS' && 
   <div className='services-details-expertise-description'>
   <h3> Learning Management System (LMS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Course Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Delivery </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Assessment and Testing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Tracking and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Communication and Collaboration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Certification and Accreditation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Mobile Access </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customization and Branding </p>
                           </div>
   }


   
{
   activeButton === 'handleERP' && 
   <div className='services-details-expertise-description'>
   <h3> Enterprise Resource Planning (ERP)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Financial Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Human Resources Management (HRM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Supply Chain Management (SCM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Manufacturing and Production</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Relationship Management (CRM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Project Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Sales and Distribution </p>
   <p><i className="fa-solid fa-circle-dot"></i> Business Intelligence and Reporting</p>
   <p><i className="fa-solid fa-circle-dot"></i> Procurement and Vendor Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Asset Managementy</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customizable User Interface</p>
                           </div>
   }


   
{
   activeButton === 'handlePOS' && 
<div className='services-details-expertise-description'>
   <h3> Point of Sale (POS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Sales and Transactions</p>
   <p><i className="fa-solid fa-circle-dot"></i> Inventory Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Product Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Reporting and Analytics </p>
   <p><i className="fa-solid fa-circle-dot"></i> Employee Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Interface and Usability </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Advanced Reporting and Analytics</p>
                           </div>
   }


   
{
   activeButton === 'handleWebDesign' && 
   <div className='services-details-expertise-description'>
   <h3> Responsive Web Design</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Fluid Grid Layouts</p>
   <p><i className="fa-solid fa-circle-dot"></i> Flexible Images and Media </p>
   <p><i className="fa-solid fa-circle-dot"></i> Media Queries </p>
   <p><i className="fa-solid fa-circle-dot"></i> Responsive Typography </p>
   <p><i className="fa-solid fa-circle-dot"></i> Adaptive Navigation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Viewport Meta Tag </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Browser Compatibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> Accessibility Considerations </p>
   <p><i className="fa-solid fa-circle-dot"></i> Custom Breakpoints</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience Testing</p>
                           </div>
   }


   
{
   activeButton === 'handleMaintenance' && 
   <div className='services-details-expertise-description'>
   <h3> Website Maintenance and Support</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Regular Updates</p>
   <p><i className="fa-solid fa-circle-dot"></i> Backups and Recovery</p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Monitoring </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Uptime Monitoring </p>
   <p><i className="fa-solid fa-circle-dot"></i> Bug Fixes and Troubleshooting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience Improvements </p>
   <p><i className="fa-solid fa-circle-dot"></i> Compliance Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Support Integration</p>
                           </div>
   }


   
{
   activeButton === 'handleUIUX' && 
   <div className='services-details-expertise-description'>
   <h3>  UI/UX Design</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Visual Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> Layout and Structure </p>
   <p><i className="fa-solid fa-circle-dot"></i> Navigation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Interactive Elements</p>
   <p><i className="fa-solid fa-circle-dot"></i> Feedback and Communication</p>
   <p><i className="fa-solid fa-circle-dot"></i> Accessibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Research and Personas</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Flow and Journey Mapping </p>
   <p><i className="fa-solid fa-circle-dot"></i> Support and Communication</p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance and Efficiency</p>
   <p><i className="fa-solid fa-circle-dot"></i> Responsiveness and Adaptability</p>
                           </div>
   }

{
   activeButton === 'handleSEO' && 
<div className='services-details-expertise-description'>
   <h3> SEO and Digital Marketing</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> On-Page SEO</p>
   <p><i className="fa-solid fa-circle-dot"></i> Technical SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Off-Page SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Local SEO Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Marketing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Social Media Marketing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Pay-Per-Click (PPC) Advertising </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Insights</p>
   <p><i className="fa-solid fa-circle-dot"></i> Webinar and Event Marketing </p>
                           </div>
   }


{
   activeButton === 'handleDeploy' && 
   <div className='services-details-expertise-description'>
   <h3>  Server Deploy and Domain Management</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Server Provisioning</p>
   <p><i className="fa-solid fa-circle-dot"></i> Server Configuration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Automation and Orchestration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Scalability and Load Balancing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security </p>
   <p><i className="fa-solid fa-circle-dot"></i> Monitoring and Performance</p>
   <p><i className="fa-solid fa-circle-dot"></i> Backup and Recovery</p>
   <p><i className="fa-solid fa-circle-dot"></i> Deployment Pipelines</p>
   <p><i className="fa-solid fa-circle-dot"></i> Access Control</p>
   <p><i className="fa-solid fa-circle-dot"></i>Traffic Analytics</p>
   <p><i className="fa-solid fa-circle-dot"></i> Compliance and Legal</p>
                           </div>
   }


                     
                   </div>

               </div>

           </div>

           </div>
       
       <div className='services-details-development-process-div'>
       <h3>Empower Your Business Potential Through Our Mobile App Development.</h3>
       <p className='services-details-development-process-div-p'>Creating and delivering smart solutions to help your business succeed in the future.</p>

       <div className='row gx-3 gy-3'>
       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Initial Consultation</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={consultation} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our first step is to conduct a consultation to understand your business goals and vision, which helps us gather the necessary details to create a customized web development plan aligned with your objectives.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
       <h4 className="services-process-card-title">Requirement Analysis and Project Planning</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={projectPlanning} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We start with a comprehensive analysis of your requirements and develop a detailed project plan that specifies the scope, sets timelines, and allocates resources to ensure a well-structured and efficient development workflow.</p>
       </div>
     </div>
       </div>



       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Design Creation and Prototype Testing</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={prototype} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We produce wireframes and prototypes to showcase your website’s design and functionality. Our team prioritizes user experience (UX) and user interface (UI) to ensure the site is both easy to use and visually appealing. You will have the opportunity to review and offer feedback for design improvements.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Development</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={development} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our expert developers transform the design into a functional website by crafting clean, efficient, and scalable code. Utilizing the latest technologies and best practices, we ensure your site is robust, secure, and performs optimally on all devices.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Testing and Quality Assurance</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={testing} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Quality is our highest concern. Our QA team rigorously tests the website to detect and address any issues. We undertake various testing methods—such as functional, performance, security, and user acceptance testing (UAT)—to ensure the website upholds the highest quality standards.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Deployment</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={deployment} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">After the website clears all testing phases, we proceed with deployment. Our team facilitates a seamless transition to the live environment and offers support during the initial launch to address any immediate concerns and ensure smooth operation.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Training and Documentation</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={training} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We deliver extensive training to help you and your team operate the new website efficiently. Furthermore, we provide complete documentation that includes both user guides and technical information.</p>
       </div>
     </div>
       </div>

       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Maintenance and Support</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={maintenance} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">After deployment, our support doesn’t end. We provide regular maintenance and support to keep your website updated and running efficiently. Our team is on standby 24/7 to address any issues, execute updates, and make sure the website aligns with your changing business requirements.</p>
       </div>
     </div>
       </div>

       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Continuous Improvement</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={improvement} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our focus is on constant improvement. We partner with you to leverage user feedback and adapt to new business requirements, making ongoing enhancements to your website to ensure it remains effective and in sync with your long-term vision.</p>
       </div>
     </div>
       </div>

       </div>
       </div>

           </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>


              {/* ===================== */}
      {/* Video Editing Services Modal */}
      {/* ===================== */}

      <div
        className="modal fade modal-xl"
        id="exampleModalvideo"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
        <div className="modal-content p-4">
            <div className="modal-header">
              {/* <h1
                className="modal-title fs-5 project-details-information-title"
                id="exampleModalLabel"
              >
               Modal Title Video Editing
              </h1> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
           

           <div className='services-details-expertise-div'>
           <h3>Explore Our Service <span>Expertise</span></h3>
           <p className='services-details-expertise-div-p'>See How Our Expertise Transforms Challenges into Opportunities Through Tailored Service Solutions</p>

           <div className='services-details-expertise'>
               <div className='row gx-5 gy-5'>
                   <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                       <div className='services-details-expertise-title' onClick={handleCustomWebClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Custom Mobile App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleMultiVendorClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Cross-Platform App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>


                       <div className='services-details-expertise-title' onClick={handleCMSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Native App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleCRMClick}>
                       <p> <i className="fa-solid fa-cloud"></i> UI/UX Design</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleLMSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Mobile App Testing </p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleERPClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Maintenance and Support</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handlePOSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> App Store Optimization (ASO)</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleWebDesignClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Integration with Wearable Devices</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleMaintenanceClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Enterprise Mobility Solutions</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleUIUXClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Cloud-Based Mobile Apps</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                   </div>

                   <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>

                   {
   activeButton === 'handleCustomWeb' && 
   <div className='services-details-expertise-description'>
   <h3> Custom Mobile App Development</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> User Interface (UI) Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience (UX) Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> Functionality and Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Backend Development </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Platform Development </p>
   <p><i className="fa-solid fa-circle-dot"></i> App Store Integration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Testing and Quality Assurance </p>
   <p><i className="fa-solid fa-circle-dot"></i> Geolocation Services </p>
   <p><i className="fa-solid fa-circle-dot"></i> Custom APIs </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Feedback and Support</p>
                           </div>
   }

                   {
   activeButton === 'handleMultiVendor' && 
   <div className='services-details-expertise-description'>
   <h3>Cross-Platform App Development</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Unified Codebase</p>
   <p><i className="fa-solid fa-circle-dot"></i> Frameworks and Tools</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Interface (UI) Design </p>
   <p><i className="fa-solid fa-circle-dot"></i> Order and Payment Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization</p>
   <p><i className="fa-solid fa-circle-dot"></i> Device and Platform Integration</p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Platform Libraries</p>
   <p><i className="fa-solid fa-circle-dot"></i> Testing and Debugging</p>
   <p><i className="fa-solid fa-circle-dot"></i> Deployment and Distribution</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience (UX)</p>
                           </div>
 }


 
{
   activeButton === 'handleCMS' && 
   <div className='services-details-expertise-description'>
   <h3>  Content Management Systems (CMS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> User Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Creation and Editing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Organization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Template and Design Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> SEO and Metadata </p>
   <p><i className="fa-solid fa-circle-dot"></i> Search and Navigation</p>
   <p><i className="fa-solid fa-circle-dot"></i> Multimedia Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting</p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features</p>
   <p><i className="fa-solid fa-circle-dot"></i> Multi-language and Localization</p>
   <p><i className="fa-solid fa-circle-dot"></i> API and Integrations</p>
                           </div>
   }


   
{
   activeButton === 'handleCRM' && 
   <div className='services-details-expertise-description'>
   <h3> Customer Relationship Management (CRM)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Contact Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Lead and Opportunity Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Sales Automation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Service and Support </p>
   <p><i className="fa-solid fa-circle-dot"></i> Email and Communication Tools </p>
   <p><i className="fa-solid fa-circle-dot"></i> Reporting and Analytics </p>
   <p><i className="fa-solid fa-circle-dot"></i> Task and Activity Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> Data Management and Security </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customization and Flexibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> Contract and Agreement Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration with Financial Systems </p>
                           </div>
   }


   
{
   activeButton === 'handleLMS' && 
   <div className='services-details-expertise-description'>
   <h3> Learning Management System (LMS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Course Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Delivery </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Assessment and Testing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Tracking and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Communication and Collaboration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Certification and Accreditation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Mobile Access </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customization and Branding </p>
                           </div>
   }


   
{
   activeButton === 'handleERP' && 
   <div className='services-details-expertise-description'>
   <h3> Enterprise Resource Planning (ERP)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Financial Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Human Resources Management (HRM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Supply Chain Management (SCM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Manufacturing and Production</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Relationship Management (CRM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Project Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Sales and Distribution </p>
   <p><i className="fa-solid fa-circle-dot"></i> Business Intelligence and Reporting</p>
   <p><i className="fa-solid fa-circle-dot"></i> Procurement and Vendor Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Asset Managementy</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customizable User Interface</p>
                           </div>
   }


   
{
   activeButton === 'handlePOS' && 
<div className='services-details-expertise-description'>
   <h3> Point of Sale (POS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Sales and Transactions</p>
   <p><i className="fa-solid fa-circle-dot"></i> Inventory Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Product Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Reporting and Analytics </p>
   <p><i className="fa-solid fa-circle-dot"></i> Employee Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Interface and Usability </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Advanced Reporting and Analytics</p>
                           </div>
   }


   
{
   activeButton === 'handleWebDesign' && 
   <div className='services-details-expertise-description'>
   <h3> Responsive Web Design</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Fluid Grid Layouts</p>
   <p><i className="fa-solid fa-circle-dot"></i> Flexible Images and Media </p>
   <p><i className="fa-solid fa-circle-dot"></i> Media Queries </p>
   <p><i className="fa-solid fa-circle-dot"></i> Responsive Typography </p>
   <p><i className="fa-solid fa-circle-dot"></i> Adaptive Navigation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Viewport Meta Tag </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Browser Compatibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> Accessibility Considerations </p>
   <p><i className="fa-solid fa-circle-dot"></i> Custom Breakpoints</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience Testing</p>
                           </div>
   }


   
{
   activeButton === 'handleMaintenance' && 
   <div className='services-details-expertise-description'>
   <h3> Website Maintenance and Support</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Regular Updates</p>
   <p><i className="fa-solid fa-circle-dot"></i> Backups and Recovery</p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Monitoring </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Uptime Monitoring </p>
   <p><i className="fa-solid fa-circle-dot"></i> Bug Fixes and Troubleshooting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience Improvements </p>
   <p><i className="fa-solid fa-circle-dot"></i> Compliance Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Support Integration</p>
                           </div>
   }


   
{
   activeButton === 'handleUIUX' && 
   <div className='services-details-expertise-description'>
   <h3>  UI/UX Design</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Visual Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> Layout and Structure </p>
   <p><i className="fa-solid fa-circle-dot"></i> Navigation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Interactive Elements</p>
   <p><i className="fa-solid fa-circle-dot"></i> Feedback and Communication</p>
   <p><i className="fa-solid fa-circle-dot"></i> Accessibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Research and Personas</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Flow and Journey Mapping </p>
   <p><i className="fa-solid fa-circle-dot"></i> Support and Communication</p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance and Efficiency</p>
   <p><i className="fa-solid fa-circle-dot"></i> Responsiveness and Adaptability</p>
                           </div>
   }

{
   activeButton === 'handleSEO' && 
<div className='services-details-expertise-description'>
   <h3> SEO and Digital Marketing</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> On-Page SEO</p>
   <p><i className="fa-solid fa-circle-dot"></i> Technical SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Off-Page SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Local SEO Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Marketing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Social Media Marketing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Pay-Per-Click (PPC) Advertising </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Insights</p>
   <p><i className="fa-solid fa-circle-dot"></i> Webinar and Event Marketing </p>
                           </div>
   }


{
   activeButton === 'handleDeploy' && 
   <div className='services-details-expertise-description'>
   <h3>  Server Deploy and Domain Management</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Server Provisioning</p>
   <p><i className="fa-solid fa-circle-dot"></i> Server Configuration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Automation and Orchestration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Scalability and Load Balancing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security </p>
   <p><i className="fa-solid fa-circle-dot"></i> Monitoring and Performance</p>
   <p><i className="fa-solid fa-circle-dot"></i> Backup and Recovery</p>
   <p><i className="fa-solid fa-circle-dot"></i> Deployment Pipelines</p>
   <p><i className="fa-solid fa-circle-dot"></i> Access Control</p>
   <p><i className="fa-solid fa-circle-dot"></i>Traffic Analytics</p>
   <p><i className="fa-solid fa-circle-dot"></i> Compliance and Legal</p>
                           </div>
   }


                     
                   </div>

               </div>

           </div>

           </div>
       
       <div className='services-details-development-process-div'>
       <h3>Empower Your Business Potential Through Our Mobile App Development.</h3>
       <p className='services-details-development-process-div-p'>Creating and delivering smart solutions to help your business succeed in the future.</p>

       <div className='row gx-3 gy-3'>
       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Initial Consultation</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={consultation} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our first step is to conduct a consultation to understand your business goals and vision, which helps us gather the necessary details to create a customized web development plan aligned with your objectives.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
       <h4 className="services-process-card-title">Requirement Analysis and Project Planning</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={projectPlanning} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We start with a comprehensive analysis of your requirements and develop a detailed project plan that specifies the scope, sets timelines, and allocates resources to ensure a well-structured and efficient development workflow.</p>
       </div>
     </div>
       </div>



       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Design Creation and Prototype Testing</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={prototype} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We produce wireframes and prototypes to showcase your website’s design and functionality. Our team prioritizes user experience (UX) and user interface (UI) to ensure the site is both easy to use and visually appealing. You will have the opportunity to review and offer feedback for design improvements.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Development</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={development} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our expert developers transform the design into a functional website by crafting clean, efficient, and scalable code. Utilizing the latest technologies and best practices, we ensure your site is robust, secure, and performs optimally on all devices.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Testing and Quality Assurance</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={testing} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Quality is our highest concern. Our QA team rigorously tests the website to detect and address any issues. We undertake various testing methods—such as functional, performance, security, and user acceptance testing (UAT)—to ensure the website upholds the highest quality standards.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Deployment</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={deployment} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">After the website clears all testing phases, we proceed with deployment. Our team facilitates a seamless transition to the live environment and offers support during the initial launch to address any immediate concerns and ensure smooth operation.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Training and Documentation</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={training} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We deliver extensive training to help you and your team operate the new website efficiently. Furthermore, we provide complete documentation that includes both user guides and technical information.</p>
       </div>
     </div>
       </div>

       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Maintenance and Support</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={maintenance} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">After deployment, our support doesn’t end. We provide regular maintenance and support to keep your website updated and running efficiently. Our team is on standby 24/7 to address any issues, execute updates, and make sure the website aligns with your changing business requirements.</p>
       </div>
     </div>
       </div>

       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Continuous Improvement</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={improvement} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our focus is on constant improvement. We partner with you to leverage user feedback and adapt to new business requirements, making ongoing enhancements to your website to ensure it remains effective and in sync with your long-term vision.</p>
       </div>
     </div>
       </div>

       </div>
       </div>

           </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>


      {/* ===================== */}
      {/* Digital Marketing Services Modal */}
      {/* ===================== */}

      <div
        className="modal fade modal-xl"
        id="exampleModalDigital"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
        <div className="modal-content p-4">
            <div className="modal-header">
              {/* <h1
                className="modal-title fs-5 project-details-information-title"
                id="exampleModalLabel"
              >
               Modal Title Digital Marketing
              </h1> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
           

           <div className='services-details-expertise-div'>
           <h3>Explore Our Service <span>Expertise</span></h3>
           <p className='services-details-expertise-div-p'>See How Our Expertise Transforms Challenges into Opportunities Through Tailored Service Solutions</p>

           <div className='services-details-expertise'>
               <div className='row gx-5 gy-5'>
                   <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                       <div className='services-details-expertise-title' onClick={handleCustomWebClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Custom Mobile App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleMultiVendorClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Cross-Platform App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>


                       <div className='services-details-expertise-title' onClick={handleCMSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Native App Development</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleCRMClick}>
                       <p> <i className="fa-solid fa-cloud"></i> UI/UX Design</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleLMSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Mobile App Testing </p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleERPClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Maintenance and Support</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handlePOSClick}>
                       <p> <i className="fa-solid fa-cloud"></i> App Store Optimization (ASO)</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleWebDesignClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Integration with Wearable Devices</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleMaintenanceClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Enterprise Mobility Solutions</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                       <div className='services-details-expertise-title' onClick={handleUIUXClick}>
                       <p> <i className="fa-solid fa-cloud"></i> Cloud-Based Mobile Apps</p>
                       <p><i className="fa-solid fa-arrow-right"></i></p>
                       </div>

                   </div>

                   <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>

                   {
   activeButton === 'handleCustomWeb' && 
   <div className='services-details-expertise-description'>
   <h3> Custom Mobile App Development</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> User Interface (UI) Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience (UX) Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> Functionality and Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Backend Development </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Platform Development </p>
   <p><i className="fa-solid fa-circle-dot"></i> App Store Integration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Testing and Quality Assurance </p>
   <p><i className="fa-solid fa-circle-dot"></i> Geolocation Services </p>
   <p><i className="fa-solid fa-circle-dot"></i> Custom APIs </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Feedback and Support</p>
                           </div>
   }

                   {
   activeButton === 'handleMultiVendor' && 
   <div className='services-details-expertise-description'>
   <h3>Cross-Platform App Development</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Unified Codebase</p>
   <p><i className="fa-solid fa-circle-dot"></i> Frameworks and Tools</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Interface (UI) Design </p>
   <p><i className="fa-solid fa-circle-dot"></i> Order and Payment Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization</p>
   <p><i className="fa-solid fa-circle-dot"></i> Device and Platform Integration</p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Platform Libraries</p>
   <p><i className="fa-solid fa-circle-dot"></i> Testing and Debugging</p>
   <p><i className="fa-solid fa-circle-dot"></i> Deployment and Distribution</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience (UX)</p>
                           </div>
 }


 
{
   activeButton === 'handleCMS' && 
   <div className='services-details-expertise-description'>
   <h3>  Content Management Systems (CMS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> User Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Creation and Editing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Organization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Template and Design Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> SEO and Metadata </p>
   <p><i className="fa-solid fa-circle-dot"></i> Search and Navigation</p>
   <p><i className="fa-solid fa-circle-dot"></i> Multimedia Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting</p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features</p>
   <p><i className="fa-solid fa-circle-dot"></i> Multi-language and Localization</p>
   <p><i className="fa-solid fa-circle-dot"></i> API and Integrations</p>
                           </div>
   }


   
{
   activeButton === 'handleCRM' && 
   <div className='services-details-expertise-description'>
   <h3> Customer Relationship Management (CRM)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Contact Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Lead and Opportunity Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Sales Automation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Service and Support </p>
   <p><i className="fa-solid fa-circle-dot"></i> Email and Communication Tools </p>
   <p><i className="fa-solid fa-circle-dot"></i> Reporting and Analytics </p>
   <p><i className="fa-solid fa-circle-dot"></i> Task and Activity Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> Data Management and Security </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customization and Flexibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> Contract and Agreement Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration with Financial Systems </p>
                           </div>
   }


   
{
   activeButton === 'handleLMS' && 
   <div className='services-details-expertise-description'>
   <h3> Learning Management System (LMS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Course Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Delivery </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Assessment and Testing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Tracking and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Communication and Collaboration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Certification and Accreditation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Mobile Access </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customization and Branding </p>
                           </div>
   }


   
{
   activeButton === 'handleERP' && 
   <div className='services-details-expertise-description'>
   <h3> Enterprise Resource Planning (ERP)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Financial Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Human Resources Management (HRM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Supply Chain Management (SCM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Manufacturing and Production</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Relationship Management (CRM) </p>
   <p><i className="fa-solid fa-circle-dot"></i> Project Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Sales and Distribution </p>
   <p><i className="fa-solid fa-circle-dot"></i> Business Intelligence and Reporting</p>
   <p><i className="fa-solid fa-circle-dot"></i> Procurement and Vendor Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Asset Managementy</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customizable User Interface</p>
                           </div>
   }


   
{
   activeButton === 'handlePOS' && 
<div className='services-details-expertise-description'>
   <h3> Point of Sale (POS)</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Sales and Transactions</p>
   <p><i className="fa-solid fa-circle-dot"></i> Inventory Management</p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Product Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Reporting and Analytics </p>
   <p><i className="fa-solid fa-circle-dot"></i> Employee Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Integration Capabilities </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Interface and Usability </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Advanced Reporting and Analytics</p>
                           </div>
   }


   
{
   activeButton === 'handleWebDesign' && 
   <div className='services-details-expertise-description'>
   <h3> Responsive Web Design</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Fluid Grid Layouts</p>
   <p><i className="fa-solid fa-circle-dot"></i> Flexible Images and Media </p>
   <p><i className="fa-solid fa-circle-dot"></i> Media Queries </p>
   <p><i className="fa-solid fa-circle-dot"></i> Responsive Typography </p>
   <p><i className="fa-solid fa-circle-dot"></i> Adaptive Navigation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Viewport Meta Tag </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Cross-Browser Compatibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> Accessibility Considerations </p>
   <p><i className="fa-solid fa-circle-dot"></i> Custom Breakpoints</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience Testing</p>
                           </div>
   }


   
{
   activeButton === 'handleMaintenance' && 
   <div className='services-details-expertise-description'>
   <h3> Website Maintenance and Support</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Regular Updates</p>
   <p><i className="fa-solid fa-circle-dot"></i> Backups and Recovery</p>
   <p><i className="fa-solid fa-circle-dot"></i> Security Monitoring </p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance Optimization </p>
   <p><i className="fa-solid fa-circle-dot"></i> Uptime Monitoring </p>
   <p><i className="fa-solid fa-circle-dot"></i> Bug Fixes and Troubleshooting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Experience Improvements </p>
   <p><i className="fa-solid fa-circle-dot"></i> Compliance Management </p>
   <p><i className="fa-solid fa-circle-dot"></i> Customer Support Integration</p>
                           </div>
   }


   
{
   activeButton === 'handleUIUX' && 
   <div className='services-details-expertise-description'>
   <h3>  UI/UX Design</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Visual Design</p>
   <p><i className="fa-solid fa-circle-dot"></i> Layout and Structure </p>
   <p><i className="fa-solid fa-circle-dot"></i> Navigation </p>
   <p><i className="fa-solid fa-circle-dot"></i> Interactive Elements</p>
   <p><i className="fa-solid fa-circle-dot"></i> Feedback and Communication</p>
   <p><i className="fa-solid fa-circle-dot"></i> Accessibility </p>
   <p><i className="fa-solid fa-circle-dot"></i> User Research and Personas</p>
   <p><i className="fa-solid fa-circle-dot"></i> User Flow and Journey Mapping </p>
   <p><i className="fa-solid fa-circle-dot"></i> Support and Communication</p>
   <p><i className="fa-solid fa-circle-dot"></i> Performance and Efficiency</p>
   <p><i className="fa-solid fa-circle-dot"></i> Responsiveness and Adaptability</p>
                           </div>
   }

{
   activeButton === 'handleSEO' && 
<div className='services-details-expertise-description'>
   <h3> SEO and Digital Marketing</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> On-Page SEO</p>
   <p><i className="fa-solid fa-circle-dot"></i> Technical SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Off-Page SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content SEO </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Reporting </p>
   <p><i className="fa-solid fa-circle-dot"></i> Local SEO Features </p>
   <p><i className="fa-solid fa-circle-dot"></i> Content Marketing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Social Media Marketing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Pay-Per-Click (PPC) Advertising </p>
   <p><i className="fa-solid fa-circle-dot"></i> Analytics and Insights</p>
   <p><i className="fa-solid fa-circle-dot"></i> Webinar and Event Marketing </p>
                           </div>
   }


{
   activeButton === 'handleDeploy' && 
   <div className='services-details-expertise-description'>
   <h3>  Server Deploy and Domain Management</h3>
   
   <p><i className="fa-solid fa-circle-dot"></i> Server Provisioning</p>
   <p><i className="fa-solid fa-circle-dot"></i> Server Configuration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Automation and Orchestration </p>
   <p><i className="fa-solid fa-circle-dot"></i> Scalability and Load Balancing </p>
   <p><i className="fa-solid fa-circle-dot"></i> Security </p>
   <p><i className="fa-solid fa-circle-dot"></i> Monitoring and Performance</p>
   <p><i className="fa-solid fa-circle-dot"></i> Backup and Recovery</p>
   <p><i className="fa-solid fa-circle-dot"></i> Deployment Pipelines</p>
   <p><i className="fa-solid fa-circle-dot"></i> Access Control</p>
   <p><i className="fa-solid fa-circle-dot"></i>Traffic Analytics</p>
   <p><i className="fa-solid fa-circle-dot"></i> Compliance and Legal</p>
                           </div>
   }


                     
                   </div>

               </div>

           </div>

           </div>
       
       <div className='services-details-development-process-div'>
       <h3>Empower Your Business Potential Through Our Mobile App Development.</h3>
       <p className='services-details-development-process-div-p'>Creating and delivering smart solutions to help your business succeed in the future.</p>

       <div className='row gx-3 gy-3'>
       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Initial Consultation</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={consultation} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our first step is to conduct a consultation to understand your business goals and vision, which helps us gather the necessary details to create a customized web development plan aligned with your objectives.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
       <h4 className="services-process-card-title">Requirement Analysis and Project Planning</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={projectPlanning} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We start with a comprehensive analysis of your requirements and develop a detailed project plan that specifies the scope, sets timelines, and allocates resources to ensure a well-structured and efficient development workflow.</p>
       </div>
     </div>
       </div>



       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Design Creation and Prototype Testing</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={prototype} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We produce wireframes and prototypes to showcase your website’s design and functionality. Our team prioritizes user experience (UX) and user interface (UI) to ensure the site is both easy to use and visually appealing. You will have the opportunity to review and offer feedback for design improvements.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Development</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={development} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our expert developers transform the design into a functional website by crafting clean, efficient, and scalable code. Utilizing the latest technologies and best practices, we ensure your site is robust, secure, and performs optimally on all devices.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Testing and Quality Assurance</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={testing} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Quality is our highest concern. Our QA team rigorously tests the website to detect and address any issues. We undertake various testing methods—such as functional, performance, security, and user acceptance testing (UAT)—to ensure the website upholds the highest quality standards.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Deployment</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={deployment} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">After the website clears all testing phases, we proceed with deployment. Our team facilitates a seamless transition to the live environment and offers support during the initial launch to address any immediate concerns and ensure smooth operation.</p>
       </div>
     </div>
       </div>


       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Training and Documentation</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={training} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">We deliver extensive training to help you and your team operate the new website efficiently. Furthermore, we provide complete documentation that includes both user guides and technical information.</p>
       </div>
     </div>
       </div>

       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Maintenance and Support</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={maintenance} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">After deployment, our support doesn’t end. We provide regular maintenance and support to keep your website updated and running efficiently. Our team is on standby 24/7 to address any issues, execute updates, and make sure the website aligns with your changing business requirements.</p>
       </div>
     </div>
       </div>

       <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
       <div className="services-process-card">
       <div className="services-process-card-body">
         <h4 className="services-process-card-title">Continuous Improvement</h4>
         <div className="services-process-card-image" style={{color: "#213661"}}>
           {/* <i className="fas fa-laptop-code fa-5x"></i> */}
           <img src={improvement} alt="process_image"/>
         </div>
         <p className="services-process-card-text ">Our focus is on constant improvement. We partner with you to leverage user feedback and adapt to new business requirements, making ongoing enhancements to your website to ensure it remains effective and in sync with your long-term vision.</p>
       </div>
     </div>
       </div>

       </div>
       </div>

           </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeServices;
